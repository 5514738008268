import { RouteObject } from "react-router-dom";

export const loginRoutes: RouteObject[] = [
  {
    lazy: async () => {
      const { Page } = await import("./page");
      return { Component: Page };
    },
    children: [
      {
        index: true,
        lazy: async () => {
          const { LoginDispatcher } = await import("./login-dispatcher");
          return { element: <LoginDispatcher /> };
        },
      },
    ],
  },
];
