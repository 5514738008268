import { StepType } from "@redotech/redo-customer-portal-shared/hooks/useReturnConfirmation/use-steps";
import { Resource } from "@redotech/redo-model/localization/resource";
import CheckCircleFilled from "@redotech/redo-web/arbiter-icon/check-circle_filled.svg";
import PlaceHolder from "@redotech/redo-web/arbiter-icon/placeholder_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Icon } from "@redotech/redo-web/icon";
import { Text } from "@redotech/redo-web/text";
import { ReactElement } from "react";
import { InStoreLocationsButton } from "./in-store-locations";
import { InStorePackingSlipButton } from "./in-store-packing-slip-button";
import { LabelButtons } from "./label-buttons";
import { ReturnLocationsButton } from "./return-locations-button";

export function ReturnStepsCard({
  returnId,
  copyResource,
  steps,
}: {
  returnId: string;
  copyResource: Resource | undefined;
  steps: StepType[] | undefined;
}) {
  function getStepComponent(step: StepType): ReactElement | null {
    switch (step.type) {
      case "drop-off-package":
        return <ReturnLocationsButton trackable={step.trackable} />;
      case "exchange-order":
        return null;
      case "green-return-instructions":
        return null;
      case "green-return-processed":
        return null;
      case "happy-returns-drop-off":
        return null;
      case "happy-returns-processed":
        return null;
      case "in-store-print-packing-slip":
        return (
          <InStorePackingSlipButton packingSlipLink={step.packingSlipLink} />
        );

      case "in-store-processed":
        return null;
      case "in-store-return":
        return <InStoreLocationsButton returnId={returnId} />;
      case "manual-review":
        return null;
      case "none":
        return null;
      case "pickup-attach-label":
        return null;
      case "pickup-place-package":
        return null;
      case "pickup-print-label":
        return step.shipmentInfo ? (
          <LabelButtons
            carrier={step.shipmentInfo.carrier}
            commercialInvoice={step.shipmentInfo.commercialInvoice}
            trackable={step.shipmentInfo.trackable}
          />
        ) : null;
      case "processed":
        return null;
      case "processed-repair":
        return null; //TODO: TrackRepairButton
      case "ship-back-repair":
        return null;
      case "shipment-transit":
        return step.shipmentInfo ? (
          <LabelButtons
            carrier={step.shipmentInfo.carrier}
            commercialInvoice={step.shipmentInfo.commercialInvoice}
            trackable={step.shipmentInfo.trackable}
          />
        ) : null;
      case "warning":
        return null;
    }
  }
  return (
    <Flex flexDirection="column" gap="3xl" pb="sm" pt="sm">
      <Text fontSize="lg" fontWeight="semibold">
        {copyResource?.STATUS_STEPS_TITLE}
      </Text>
      <Flex flexDirection="column">
        {steps?.map((step: StepType, i) => {
          return (
            <Flex flexDirection="row" gap="sm" key={i}>
              <Flex align="center" flexDirection="column">
                <Flex flexDirection="column">
                  <Icon
                    arbiterIconSvg={(props) =>
                      step.complete
                        ? CheckCircleFilled(props)
                        : PlaceHolder(props)
                    }
                    color={step.complete ? "success" : "light"}
                    size="compact"
                  />
                </Flex>
                {i != steps.length - 1 && (
                  <Flex
                    flex={1}
                    flexDirection="column"
                    pb="xxs"
                    pt="xxs"
                    style={{
                      borderColor: "#E5E5E5",
                      borderWidth: 1,
                      borderRadius: 12,
                      borderStyle: "solid",
                      width: "2px",
                    }}
                  />
                )}
              </Flex>

              <Flex flex={1} flexDirection="column" pb="sm">
                <Text fontSize="md" fontWeight="medium">
                  {step.title}
                </Text>
                <Text fontSize="sm" fontWeight="regular">
                  {step.subtitle}
                </Text>
                {getStepComponent(step)}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}
