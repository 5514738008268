import { ReturnableItem } from "@redotech/redo-model/return-flow";
import { ReactNode, createContext, useState } from "react";

const CurrentItemBeingReturnedContext = createContext<{
  currentItemBeingReturned: ReturnableItem | undefined;
  setCurrentItemBeingReturned: (
    currentItemBeingReturned: ReturnableItem | undefined,
  ) => void;
}>({
  currentItemBeingReturned: undefined,
  setCurrentItemBeingReturned: (
    currentItemBeingReturned: ReturnableItem | undefined,
  ) => {},
});

const CurrentItemBeingReturnedContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [currentItemBeingReturned, setCurrentItemBeingReturned] = useState<
    ReturnableItem | undefined
  >(undefined);

  return (
    <CurrentItemBeingReturnedContext.Provider
      value={{ currentItemBeingReturned, setCurrentItemBeingReturned }}
    >
      {children}
    </CurrentItemBeingReturnedContext.Provider>
  );
};

export {
  CurrentItemBeingReturnedContext,
  CurrentItemBeingReturnedContextProvider,
};
