import { Currency } from "@redotech/money/currencies";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import {
  CURRENCY_FORMAT,
  ReturnItemParams,
  subtitleWithoutDefault,
  variantsWithoutDefault,
} from "../../util";

interface UseItemDetailResponse {
  formattedItemValue: string;
  formattedPrice?: string;
  texts: {
    title: string;
    subtitle?: string;
    textStrings: string[];
    discountExplanation?: string;
    badgeText?: string;
    bonusText?: string;
  };
  loading: boolean;
  imageUri: string;
  bonusBadge?: { text: string; color: PillTheme };
  returnTypeBadges?: { text: string; color: PillTheme }[];
}

export function useItemDetail({
  item,
  isVertical = false,
  bonus,
}: {
  item?: ReturnItemParams;
  isVertical?: boolean;
  bonus?: { value: number; currency: Currency };
}): UseItemDetailResponse {
  const image = item?.imageSrc;

  if (!image) {
    return {
      imageUri: "",
      loading: true,
      formattedItemValue: "0",
      formattedPrice: "0",
      texts: { title: "", textStrings: [] },
    };
  }

  const { quantity, variants } = item;

  function formatPrice(price: number, currency: Currency) {
    return CURRENCY_FORMAT(currency).format(price);
  }

  const formattedItemValue = formatPrice(Number(item.itemValue), item.currency);
  let formattedPrice;

  /** Display message for discount if applicable */
  let discountExplanation = undefined;
  if (Number(item.price) !== Number(item.itemValue)) {
    const discountFormatted = CURRENCY_FORMAT(item.currency).format(
      Number(item.price) - Number(item.itemValue),
    );
    discountExplanation = `Your discount of ${discountFormatted} has been reapplied.`;
    formattedPrice = formatPrice(Number(item.price), item.currency);
  }

  const retainedVariants = variantsWithoutDefault(variants);

  /** In horizontal mode, display quantity, price, and variants all in one line. In vertical mode, display each variant on a new line. */
  const horizontalQuantityMessage = quantity > 1 ? `Qty: ${quantity} / ` : "";
  const horizontalTextString = `${horizontalQuantityMessage}${formattedItemValue}${retainedVariants.length > 0 ? " / " : ""}${retainedVariants.map((v) => v.value).join(" / ")}`;
  const textStrings = [];
  if (isVertical) {
    textStrings.push(...retainedVariants.map((v) => `${v.name}: ${v.value}`));
    if (quantity > 1) {
      textStrings.push(`Quantity: ${quantity}`);
    }
  } else {
    textStrings.push(horizontalTextString);
  }

  return {
    loading: false,
    texts: {
      title: item.title,
      subtitle: subtitleWithoutDefault(item.subtitle),
      textStrings,
      discountExplanation,
      badgeText: item.badgeText,
    },
    formattedItemValue,
    formattedPrice,
    imageUri: image,
    bonusBadge: bonus
      ? {
          text: `+ ${CURRENCY_FORMAT(bonus.currency, "never").format(bonus.value)} ${bonus.value > 0 ? "bonus" : "fee"}`,
          color: bonus.value > 0 ? PillTheme.SUCCESS : PillTheme.DANGER,
        }
      : undefined,
    returnTypeBadges: item.returnTypeBadges,
  };
}
