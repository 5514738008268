import { getKey } from "@redotech/react-util/key";
import { SettingsContext } from "@redotech/redo-customer-portal-app/contexts/settings";
import { useInStoreLocations } from "@redotech/redo-customer-portal-shared/hooks/useReturnConfirmation/use-in-store-locations";
import { InteractiveMapLocation } from "@redotech/redo-model/draft-return/draft-return";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoCheckboxCard } from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox-card";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import MarkerPin01Svg from "@redotech/redo-web/arbiter-icon/marker-pin-01.svg";
import { Flex } from "@redotech/redo-web/flex";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Text } from "@redotech/redo-web/text";
import {
  APIProvider,
  AdvancedMarker,
  Map,
  Pin,
  useMap,
} from "@vis.gl/react-google-maps";
import { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import * as interactiveMap from "./in-store-locations.module.css";

const LocationsModal = memo(function LocationsModal({
  draftReturnId,
  returnId,
}: {
  draftReturnId?: string;
  returnId?: string;
}) {
  const settings = useContext(SettingsContext);
  const { locationsLoad } = useInStoreLocations(
    draftReturnId,
    returnId,
    settings,
  );

  if (locationsLoad.pending) {
    return <LoadingRedoAnimation />;
  }
  if (locationsLoad.error) {
    return <Text textColor="error">Error loading locations</Text>;
  }
  if (!locationsLoad.value) {
    return null;
  }

  return (
    <InteractiveMap
      key={getKey(locationsLoad.value)}
      locations={locationsLoad.value}
    />
  );
});

const LocationsList = memo(function LocationsList({
  locations,
  selectedIndex,
  selectLocation,
}: {
  locations: InteractiveMapLocation[];
  selectedIndex: number;
  selectLocation: (index: number) => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.children[selectedIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedIndex]);

  if (!locations.length) {
    return <div>No locations found</div>;
  }
  return (
    <Flex
      basis="0"
      className={interactiveMap.locationsTextContainer}
      dir="column"
      gap="2xl"
      grow={1}
      p="xl"
      ref={ref}
    >
      {locations.map((location, i) => (
        <RedoCheckboxCard
          checked={selectedIndex === i}
          key={i}
          onClick={() => selectLocation(i)}
          title={`${i + 1}. ${location.name}`}
        >
          <Flex dir="column" gap="xs">
            <Text fontSize="sm" textColor="secondary">
              {location.distance && <span>({location.distance})</span>}
            </Text>
            <Text fontSize="sm" textColor="secondary">
              {location.address.address1}
            </Text>
            {location.address.address2 && (
              <Text fontSize="sm" textColor="secondary">
                {location.address.address2}
              </Text>
            )}
            <Text fontSize="sm" textColor="secondary">
              {location.address.city}, {location.address.state || ""}{" "}
              {location.address.zip}
            </Text>
          </Flex>
        </RedoCheckboxCard>
      ))}
    </Flex>
  );
});

const LocationMap = memo(function LocationMap({
  locations,
  selectedIndex,
  selectLocation,
}: {
  locations: InteractiveMapLocation[];
  selectedIndex: number;
  selectLocation: (index: number) => void;
}) {
  const map = useMap();
  const positions = useMemo(() => {
    return locations.map((location) =>
      location.geo !== undefined
        ? { lat: location.geo!.latitude, lng: location.geo!.longitude }
        : undefined,
    );
  }, [locations]);

  useEffect(() => {
    if (map) {
      if (locations[selectedIndex].geo) {
        map.setCenter({
          lat: locations[selectedIndex].geo!.latitude,
          lng: locations[selectedIndex].geo!.longitude,
        });
        selectedIndex;
      }
    }
  }, [map, selectedIndex, locations]);

  if (locations[selectedIndex].geo === undefined) {
    return null;
  }

  return (
    <Flex className={interactiveMap.mapContainer}>
      <Map
        defaultCenter={positions[0]}
        defaultZoom={12}
        fullscreenControl={false}
        mapId="37a5dbe51230f056"
        mapTypeControl={false}
        streetViewControl={false}
      >
        {positions.map(
          (position, i) =>
            position && (
              <AdvancedMarker
                key={i}
                onClick={() => selectLocation(i)}
                position={position}
                zIndex={i === selectedIndex ? 10000 : i}
              >
                <Pin borderColor="black" scale={i === selectedIndex ? 1.25 : 1}>
                  <span
                    style={{ fontSize: i === selectedIndex ? "18px" : "14px" }}
                  >
                    {i + 1}
                  </span>
                </Pin>
              </AdvancedMarker>
            ),
        )}
      </Map>
    </Flex>
  );
});

export const InStoreLocationsButton = memo(function InStoreLocationsButton({
  draftReturnId,
  returnId,
}: {
  draftReturnId?: string;
  returnId?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Flex>
      <RedoButton
        hierarchy="secondary"
        IconLeading={() => <MarkerPin01Svg />}
        onClick={() => setIsOpen(true)}
        text="See available locations"
      />
      <RedoModal
        isOpen={isOpen}
        onModalCloseRequested={() => setIsOpen(false)}
        title="Find a store that accepts drop offs"
      >
        <LocationsModal draftReturnId={draftReturnId} returnId={returnId} />
      </RedoModal>
    </Flex>
  );
});

export const InteractiveMap = memo(function InteractiveMap({
  locations,
}: {
  locations: InteractiveMapLocation[];
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  if (!locations.length) {
    return <div>No locations found</div>;
  }

  return (
    <Flex className={interactiveMap.locationsContainer} justify="space-between">
      <LocationsList
        locations={locations}
        selectedIndex={selectedIndex}
        selectLocation={setSelectedIndex}
      />

      <APIProvider apiKey="AIzaSyBLnX3xDv_dzLIUsaKBZDW4vNJnpimI31M">
        <LocationMap
          locations={locations}
          selectedIndex={selectedIndex}
          selectLocation={setSelectedIndex}
        />
      </APIProvider>
    </Flex>
  );
});
