// extracted by mini-css-extract-plugin
export var brand = "_redotech-redo-web-src-icon-brand";
export var compact = "_redotech-redo-web-src-icon-compact";
export var extraSmall = "_redotech-redo-web-src-icon-extra-small";
export var icon = "_redotech-redo-web-src-icon-icon";
export var large = "_redotech-redo-web-src-icon-large";
export var light = "_redotech-redo-web-src-icon-light";
export var medium = "_redotech-redo-web-src-icon-medium";
export var outline = "_redotech-redo-web-src-icon-outline";
export var primaryDark = "_redotech-redo-web-src-icon-primary-dark";
export var primaryLight = "_redotech-redo-web-src-icon-primary-light";
export var primaryModern = "_redotech-redo-web-src-icon-primary-modern";
export var success = "_redotech-redo-web-src-icon-success";