// extracted by mini-css-extract-plugin
export var box = "_redotech-redo-web-src-arbiter-components-checkbox-redo-checkbox-box";
export var checked = "_redotech-redo-web-src-arbiter-components-checkbox-redo-checkbox-checked";
export var descriptionSpacer = "_redotech-redo-web-src-arbiter-components-checkbox-redo-checkbox-description-spacer";
export var disabled = "_redotech-redo-web-src-arbiter-components-checkbox-redo-checkbox-disabled";
export var icon = "_redotech-redo-web-src-arbiter-components-checkbox-redo-checkbox-icon";
export var input = "_redotech-redo-web-src-arbiter-components-checkbox-redo-checkbox-input";
export var labelSpacer = "_redotech-redo-web-src-arbiter-components-checkbox-redo-checkbox-label-spacer";
export var medium = "_redotech-redo-web-src-arbiter-components-checkbox-redo-checkbox-medium";
export var small = "_redotech-redo-web-src-arbiter-components-checkbox-redo-checkbox-small";
export var text = "_redotech-redo-web-src-arbiter-components-checkbox-redo-checkbox-text";