// extracted by mini-css-extract-plugin
export var alternate = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-alternate";
export var black = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-black";
export var blue = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-blue";
export var blueLight = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-blue-light";
export var brand = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-brand";
export var error = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-error";
export var gray = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-gray";
export var grayBlue = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-gray-blue";
export var iconWrapper = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-icon-wrapper";
export var indigo = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-indigo";
export var orange = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-orange";
export var pink = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-pink";
export var primary = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-primary";
export var purple = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-purple";
export var success = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-success";
export var warning = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-warning";
export var white = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-white";
export var whiteSuccess = "_redotech-redo-web-src-arbiter-components-badge-redo-badge-colors-white-success";