import {
  getWidgetId,
  setCustomerWidgetAuthToken,
} from "@redotech/redo-customer-portal-app/api";
import { SettingsContext } from "@redotech/redo-customer-portal-app/contexts/settings";
import { memo, ReactNode, useContext, useEffect, useState } from "react";
import { CustomerWidget } from "./utils/customer-widget";

export const CustomerWidgetWrapper = memo(function CustomerWidgetWrapper({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const settings = useContext(SettingsContext);
  const [widgetScriptLoaded, setWidgetScriptLoaded] = useState(false);

  // Create the customer widget script widget on mount
  useEffect(() => {
    if (!settings?.showCustomerWidgetInReturnApp || widgetScriptLoaded) {
      return undefined;
    }
    const widgetId = getWidgetId();
    if (!widgetId) {
      return undefined;
    }
    const customerWidget = new CustomerWidget(widgetId);
    customerWidget.addScriptToDOM();
    void setCustomerWidgetAuthToken();
    setWidgetScriptLoaded(true);

    // Remove the script and widget from the DOM on unmount
    return () => {
      customerWidget.removeWidgetFromDOM();
      customerWidget.removeScriptFromDOM();
      setWidgetScriptLoaded(false);
    };
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.showCustomerWidgetInReturnApp]);

  return <>{children}</>;
});
