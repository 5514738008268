// tes

import {
  DraftReturn,
  ShipmentMethod,
  ShipmentMethodName,
} from "@redotech/redo-model/draft-return/draft-return";
import { useCallback, useContext, useEffect, useState } from "react";
import { ReturnContext } from "../../contexts/return";
import { RpcClientContext } from "../../contexts/rpc";
import {
  getStepsInReturn,
  navigateFromStack,
  Navigator,
  RouteName,
  StepInReturn,
} from "../../navigator";

import { CustomerPortalRpcClient } from "@redotech/customer-portal-sdk/rpc/client";
import { Currency } from "@redotech/money/currencies";
import { useHandler } from "@redotech/react-util/hook";
import { Channel } from "@redotech/redo-model/return";
import { assertNever } from "@redotech/util/type";
import { ReturnAppSettings } from "../../settings";
import { ShipmentMethodOption } from "../../shipment-method/util";
import { CURRENCY_FORMAT } from "../../util";
import { getShippingMethodOptions as getShippingMethodOptionsUtil } from "./get-shipping-method-options";

export enum IconType {
  SELF_SHIP = "self ship",
  IN_STORE = "in store",
}

export type ShipmentMethodItem = {
  title: string;
  subtitle: string;
  selection: ShipmentMethod;
  icon: IconType;
  rightTitle?: string;
  selected: boolean;
  onSelect: () => void;
};

interface useShipmentMethodResponse {
  texts: {
    continueText: string;
    previousPage: string;
    howYouWant: string;
    pickBestMethod: string;
  };
  selectedMethod: ShipmentMethod | undefined;
  setSelectedMethod: (method: ShipmentMethod | undefined) => void;
  shouldDisableSelectShipmentMethod: boolean;
  onSelectShipmentMethod: (method?: ShipmentMethod) => void;
  shipmentMethodOptions: ShipmentMethodItem[];
  isLoadingShipmentOptions: boolean;
  shipmentMethodError: string | undefined;
  submitting: boolean;
}

export const shipmentMethodTypeToTitleText: Record<ShipmentMethodName, string> =
  {
    [ShipmentMethodName.PICKUP]: "Pickup",
    [ShipmentMethodName.IN_STORE]: "Return in store",
    [ShipmentMethodName.SELF_SHIP]: "Box and ship it back to us",
    [ShipmentMethodName.HAPPY_RETURNS]: "Happy returns",
    [ShipmentMethodName.SETTLEMENT]: "Settlement",
  };

export const shipmentMethodTypeToSubtitleText: Record<
  ShipmentMethodName,
  string
> = {
  [ShipmentMethodName.PICKUP]: "Pickup from my doorstep",
  [ShipmentMethodName.IN_STORE]: "Drop it off at a nearby store",
  [ShipmentMethodName.SELF_SHIP]:
    "Print a shipping label and bring it to a carrier",
  [ShipmentMethodName.HAPPY_RETURNS]: "",
  [ShipmentMethodName.SETTLEMENT]: "",
};

export const methodsToDisplay = [
  ShipmentMethodName.IN_STORE,
  ShipmentMethodName.SELF_SHIP,
] as const;
type MethodToDisplay = (typeof methodsToDisplay)[number];

export const isMethodToDisplay = (
  type: ShipmentMethodName,
): type is MethodToDisplay => {
  for (const method of methodsToDisplay) {
    if (method === type) {
      return true;
    }
  }
  return false;
};

export const skipShipmentMethodPage = async (
  draftReturn: DraftReturn,
  client: CustomerPortalRpcClient,
  settings: ReturnAppSettings,
) => {
  const shipmentMethodOptions = await getShippingMethodOptionsUtil(
    draftReturn,
    client,
    settings,
  );
  if (
    shipmentMethodOptions.filter((method) =>
      methodsToDisplay.includes(method.type as any),
    ).length === 1
  ) {
    return true;
  }

  return false;
};

export const useShipmentMethod = (
  navigation: Navigator,
  settings: ReturnAppSettings | undefined,
  channel: Channel,
): useShipmentMethodResponse => {
  const returnContext = useContext(ReturnContext);
  const client = useContext(RpcClientContext);

  const [isUpdatingMethod, setIsUpdatingMethod] = useState(false);

  const [selectedMethod, setSelectedMethod] = useState<
    ShipmentMethod | undefined
  >();

  const [shipmentMethodOptions, setShipmentMethodOptions] = useState<
    ShipmentMethodOption[] | undefined
  >();

  const [shipmentMethodError, setShipmentMethodError] = useState<
    string | undefined
  >();

  const getShippingMethodOptions = useHandler(async () => {
    try {
      setShipmentMethodError(undefined);
      const draftReturn = returnContext.draftReturn;
      if (!draftReturn || !client) {
        console.error("shipmentMethodOptions: No draft return or client");
        console.error("shipmentMethodOptions: client: ", client);
        console.error(
          "shipmentMethodOptions: draftReturn: ",
          returnContext.draftReturn,
        );
        return [];
      }

      const load = await getShippingMethodOptionsUtil(
        draftReturn,
        client,
        settings,
      );
      return load;
    } catch (e: any) {
      setShipmentMethodError(
        "Error retrieving shipment data, please back out and try again: " +
          e?.message,
      );
      return [];
    }
  });

  useEffect(() => {
    void getShippingMethodOptions().then(setShipmentMethodOptions);
  }, [returnContext.draftReturn, client, getShippingMethodOptions]);

  const selectShipmentMethod = useCallback(
    async function selectShipmentMethod(selection: ShipmentMethod) {
      console.log("selectShipmentMethod", selection);
      const draftReturn = returnContext.draftReturn;
      if (!client || !draftReturn || !settings) {
        console.error(
          "selectShipmentMethod: client, draftReturn, or settings is undefined",
        );
        console.error("selectShipmentMethod: client: ", client);
        console.error("selectShipmentMethod: draftReturn: ", draftReturn);
        console.error("selectShipmentMethod: settings: ", settings);
        return;
      }

      try {
        setIsUpdatingMethod(true);

        console.log(
          "selectShipmentMethod: setting shipment method to ",
          selection,
        );
        const { draftReturn: updatedDraftReturn } =
          await client.selectShipmentMethod({
            draftReturnId: draftReturn._id,
            shipmentMethod: selection,
          });
        console.log(
          "selectShipmentMethod: updated draft return: ",
          updatedDraftReturn,
        );
        returnContext.setDraftReturn(updatedDraftReturn);

        const isPickupAnOption = shipmentMethodOptions?.find(
          (option) => option.type === ShipmentMethodName.PICKUP,
        );

        if (
          selection.method === ShipmentMethodName.SELF_SHIP &&
          isPickupAnOption &&
          isPickupAnOption.pickupFee
        ) {
          navigation.push(RouteName.PACKAGE_PICKUP_UPSELL, {
            pickupFee: isPickupAnOption.pickupFee.toNumber(),
          });
          return;
        }

        navigateFromStack(
          await getStepsInReturn(
            updatedDraftReturn,
            settings,
            () =>
              client.calculateReturnTotals({ draftReturnId: draftReturn._id! }),
            channel,
            client,
          ),
          StepInReturn.CHOOSE_SHIPMENT_METHOD,
          navigation,
        );
      } finally {
        setIsUpdatingMethod(false);
      }
    },
    [
      channel,
      client,
      returnContext,
      setIsUpdatingMethod,
      navigation,
      settings,
      shipmentMethodOptions,
    ],
  );

  // Make method.type have to match one of the methodsToDisplay
  type ShipmentMethodOptionToDisplay = Omit<ShipmentMethodOption, "type"> & {
    type: Extract<ShipmentMethodName, MethodToDisplay>;
  };

  function getMethodInfo(
    method: ShipmentMethodOptionToDisplay,
  ): ShipmentMethodItem {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const rightTitleText = (text: string): string => {
      return text;
    };
    const item: Omit<ShipmentMethodItem, "selected" | "onSelect"> | null =
      (function () {
        switch (method.type) {
          case ShipmentMethodName.IN_STORE:
            return {
              title: shipmentMethodTypeToTitleText[ShipmentMethodName.IN_STORE],
              rightTitle: rightTitleText("Free"),
              subtitle:
                shipmentMethodTypeToSubtitleText[ShipmentMethodName.IN_STORE],
              selection: { method: ShipmentMethodName.IN_STORE },
              icon: IconType.IN_STORE,
            };
          case ShipmentMethodName.SELF_SHIP:
            return {
              title:
                shipmentMethodTypeToTitleText[ShipmentMethodName.SELF_SHIP],
              rightTitle: rightTitleText(
                method.nonPickupFee === undefined ||
                  method.nonPickupFee.toNumber() === 0
                  ? "Free"
                  : CURRENCY_FORMAT(
                      returnContext.draftReturn?.currency ?? Currency.USD,
                    ).format(method.nonPickupFee.toNumber()),
              ),
              subtitle:
                shipmentMethodTypeToSubtitleText[ShipmentMethodName.SELF_SHIP],
              selection: { method: ShipmentMethodName.SELF_SHIP },
              icon: IconType.SELF_SHIP,
            };
          default:
            assertNever(method.type);
        }
      })();
    return {
      ...item,
      selected: method.type === selectedMethod?.method,
      onSelect: () => setSelectedMethod(item.selection),
    };
  }

  function getTotalFee(method: ShipmentMethodOption): number {
    return (
      method.pickupFee?.toNumber() ?? 0 + (method.nonPickupFee?.toNumber() ?? 0)
    );
  }

  return {
    texts: {
      continueText: isUpdatingMethod ? "Loading..." : "Continue",
      previousPage: "Previous page",
      howYouWant: "How do you want to return your items?",
      pickBestMethod: "Pick the method that works best for your return.",
    },
    selectedMethod,
    setSelectedMethod,
    onSelectShipmentMethod: (method) =>
      selectShipmentMethod(method ?? selectedMethod!),
    isLoadingShipmentOptions: shipmentMethodOptions === undefined,
    shipmentMethodOptions: shipmentMethodOptions
      ? shipmentMethodOptions
          .sort((a, b) => getTotalFee(a) - getTotalFee(b))
          .filter((method): method is ShipmentMethodOptionToDisplay =>
            isMethodToDisplay(method.type),
          )
          .map((method) => getMethodInfo(method))
      : [],
    shouldDisableSelectShipmentMethod: !selectedMethod || isUpdatingMethod,
    shipmentMethodError,
    submitting: isUpdatingMethod,
  };
};
