import { z } from "zod";

export const shopAppTempLogin = {
  input: z.object({ email: z.string(), orderId: z.string() }),
  output: z.object({
    customerAuthToken: z.string(),
    customer: z.object({
      name: z.string(),
      email: z.string(),
      customerId: z.string(),
    }),
    orderId: z.string().nullish(),
  }),
};
