import { z } from "zod";

export const giftAuth = {
  input: z.object({
    gifterEmail: z.string(),
    orderIdTrackingNumber: z.string(),
  }),
  output: z.object({
    customerAuthToken: z.string(),
    customer: z.object({
      name: z.string(),
      email: z.string(),
      customerId: z.string(),
    }),
    orderId: z.string(),
  }),
};
