import { useLoad } from "@redotech/react-util/load";
import { getRedirectMapping } from "@redotech/redo-customer-portal-app/api";
import { memo, useEffect } from "react";
import {
  Path,
  RelativeRoutingType,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

export interface RedirectProps {
  to: string;
  replace?: boolean;
  relative?: RelativeRoutingType;
  preserveQuery?: boolean;
}

// Similar to react-router's Redirect component, but it can preserve the query params
export const Redirect = memo(function Redirect({
  preserveQuery,
  to,
  replace,
  relative,
}: RedirectProps) {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const path: Partial<Path> = {
      pathname: to,
      search: preserveQuery ? params.toString() : undefined,
    };

    navigate(path, { replace, relative });
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
});

export const InternalRedirect = memo(function InternalRedirect() {
  const { key } = useParams();
  // @ts-expect-error: strict-null-checks
  const redirectUrlLoad = useLoad(() => getRedirectMapping(key), []);
  const navigate = useNavigate();
  useEffect(() => {
    if (redirectUrlLoad.value) {
      navigate(redirectUrlLoad.value.redirectMapping);
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUrlLoad]);
  return null;
});
