import { z } from "zod";

export enum SendEmailAuthLinkError {
  InvalidEmail = "InvalidEmail",
  UnknownError = "UnknownError",
}
export const sendEmailAuthLink = {
  input: z.object({
    email: z.string(),
    baseUrl: z.string(),
    nextUrl: z.string().optional(),
  }),
  output: z.object({}),
};
