import { ReturnZod } from "@redotech/redo-model/return";
import { ReturnTotals } from "@redotech/redo-model/return-totals-calculator";

interface UseConfirmationSummaryParams {
  totals: ReturnTotals;
  products: ReturnZod["products"] | undefined;
}

interface UseConfirmationSummaryResponse {
  isLoading: boolean;
  productSubtotals: { title: string; total: number }[];
}

export const useConfirmationSummary = ({
  totals,
  products,
}: UseConfirmationSummaryParams): UseConfirmationSummaryResponse => {
  function getTitle(product: ReturnZod["products"][0] | undefined): string {
    let title = product?.variant_title ?? product?.product_title;

    if (title == "Default Title") {
      title = product?.product_title;
    }

    if (!title) {
      return "Product";
    }
    return title;
  }

  const productSubtotals = totals.productTotals
    .filter((p) => !p.product.isNewItem)
    .map((p) => {
      return {
        title: getTitle(
          products?.find(
            (product) => product.line_item_id === p.product.line_item_id,
          ),
        ),
        total: p.finalPrice,
      };
    });

  // Since we're not storing the lineItem id we can't know the price of this exchange item
  // const newVariantExchangeItemSubtotals = totals
  //   .flatMap((total) => total.productTotals)
  //   .filter((p) => p.product.exchangeGroupItem)
  //   .map((p) => {
  //     return {
  //       title: getTitle(
  //         products.find(
  //           (product) => product.line_item_id === (p.product.exchangeGroupItem as ,
  //         ),
  //       ),
  //       total: p.finalPrice,
  //     };
  //   });

  // const newOrderItemsSubtotals = totals
  //   .flatMap((total) => total.productTotals)
  //   .filter((p) => p.product.isNewItem && !p.product.exchangeGroupItem)
  //   .map((p) => {
  //     return {
  //       title: getTitle(
  //         products.find(
  //           (product) => product.line_item_id === p.product.line_item_id,
  //         ),
  //       ),
  //       total: p.finalPrice,
  //     };
  //   });

  return { isLoading: !products, productSubtotals };
};
