import { Flex } from "@redotech/redo-web/flex";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { memo, useEffect } from "react";

/**
 * A simple component for Google authentication.
 * This will be shown when the user clicks "Sign in with Google" and a popup window opens.
 */
const GoogleAuth = memo(function GoogleAuth() {
  const googleURL = googleFlowURL(process.env.GOOGLE_AUTH_CLIENT_ID as string);

  useEffect(() => {
    window.location.href = googleURL.toString();
  }, [googleURL]);

  return (
    <Flex align="center" flex={1} justify="center">
      <LoadingRedoAnimation />
    </Flex>
  );
});

function googleFlowURL(clientId: string) {
  const params = new URLSearchParams({
    client_id:
      process.env.GOOGLE_AUTH_CLIENT_ID ||
      "303013047044-6qmrhlma841kl67qh7sc42am047460gb.apps.googleusercontent.com",
    redirect_uri:
      process.env.GOOGLE_AUTH_REDIRECT_URI ||
      "https://customer-portal-server.getredo.com/auth/google-redirect",
    scope: [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" "),
    response_type: "code",
    access_type: "offline",
    prompt: "consent",
  });
  return new URL(
    `/o/oauth2/v2/auth?${params.toString()}`,
    "https://accounts.google.com",
  );
}

export { GoogleAuth };
