import { z } from "zod";

export enum SingleOrderAuthError {
  INVALID_EMAIL_OR_ZIP = "INVALID_EMAIL_OR_ZIP",
}
export const singleOrderAuth = {
  input: z.object({
    emailOrZip: z.string(),
    orderIdTrackingNumber: z.string(),
  }),
  output: z.object({
    customerAuthToken: z.string(),
    customer: z
      .object({ name: z.string(), email: z.string(), customerId: z.string() })
      .nullish(),
    orderId: z.string(),
  }),
};
