import { Trackable, TrackableType } from "@redotech/redo-model/trackable";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import MarkerPin from "@redotech/redo-web/arbiter-icon/marker-pin-01.svg";
import { Flex } from "@redotech/redo-web/flex";
const supportedCarrierLocations = [
  "USPS",
  "USPSReturns",
  "UspsShip",
  "FedEx",
  "FedExSmartPost",
];

export function ReturnLocationsButton({
  trackable,
}: {
  trackable: Trackable & { type: TrackableType.RETURN };
}) {
  const carrier: string | undefined =
    trackable?.shipment?._shipment?.selected_rate?.carrier;

  if (!carrier || !supportedCarrierLocations.includes(carrier)) {
    return null;
  }
  return (
    <Flex pt="xs">
      <RedoButton
        hierarchy="secondary"
        IconLeading={MarkerPin}
        onClick={async () => {
          window.open(`https://maps.apple.com/maps?daddr=${carrier}`, "_blank");
        }}
        text="Drop off locations"
      />
    </Flex>
  );
}
