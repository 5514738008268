import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
export function DisplayHtml({ html }: { html: string }) {
  const isHtml = html.includes("<") && html.includes(">");

  if (!isHtml) {
    return <Text>{html}</Text>;
  }
  return (
    <Flex gap="md" grow={1} justify="flex-start">
      <div
        // TODO parse HTML safely
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Flex>
  );
}
