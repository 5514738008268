import { Adjustment, ReturnableItem } from "@redotech/redo-model/return-flow";
import { ReactNode, createContext, useState } from "react";

export type AvailableReturnOptions = {
  shopNow?: {
    totalAmount: number;
    totalPriceAdjustment: number;
    covered: boolean;
    adjustmentOverride?: Adjustment;
    manualReviewOverride?: boolean;
    manualReviewReason?: string;
    flaggedOverride?: boolean;
    flaggedReason?: string;
    hasMerchantCovered?: boolean;
  };
  storeCredit?: {
    totalAmount: number;
    // If not all items can be returned for store credit
    totalRefundAmount?: number;
    // Includes price adjustment from store credit and refund
    //  (if totalRefundAmount is set for this option)
    totalPriceAdjustment: number;
    covered: boolean;
    adjustmentOverride?: Adjustment;
    manualReviewOverride?: boolean;
    manualReviewReason?: string;
    flaggedOverride?: boolean;
    flaggedReason?: string;
    hasMerchantCovered?: boolean;
  };
  refund?: {
    totalAmount: number;
    // If not all items can be returned for a refund
    totalStoreCreditAmount?: number;
    // Includes price adjustment from refund and store credit
    //  (if totalStoreCreditAmount is set for this option)
    totalPriceAdjustment: number;
    covered: boolean;
    instantEnabled: boolean;
    adjustmentOverride?: Adjustment;
    manualReviewOverride?: boolean;
    manualReviewReason?: string;
    flaggedOverride?: boolean;
    flaggedReason?: string;
    hasMerchantCovered?: boolean;
  };
};

const ItemsReadyForReturnContext = createContext({
  itemsReadyForReturn: [] as ReturnableItem[],
  setItemsReadyForReturn: (itemsReadyForReturn: ReturnableItem[]) => {},
  availableReturnOptions: {} as AvailableReturnOptions,
  setAvailableReturnOptions: (
    availableReturnOptions: AvailableReturnOptions,
  ) => {},
});

const ItemsReadyForReturnContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [itemsReadyForReturn, setItemsReadyForReturn] = useState<
    ReturnableItem[]
  >([]);
  const [availableReturnOptions, setAvailableReturnOptions] =
    useState<AvailableReturnOptions>({});

  return (
    <ItemsReadyForReturnContext.Provider
      value={{
        itemsReadyForReturn,
        setItemsReadyForReturn,
        availableReturnOptions,
        setAvailableReturnOptions,
      }}
    >
      {children}
    </ItemsReadyForReturnContext.Provider>
  );
};

export { ItemsReadyForReturnContext, ItemsReadyForReturnContextProvider };
