import * as spinnerCss from "./spinner.module.css";
//Replace this with spinner lottie file once designer finishes
export function Spinner() {
  return (
    <div className={spinnerCss.ldsDefault}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
