import { ReturnWarning } from "@redotech/redo-model/return";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";

export function WarningsCard({ warnings }: { warnings: ReturnWarning[] }) {
  return (
    <Flex flexDirection="column">
      <Text>There were some problems with your return.</Text>
      <Text>Here are the issues we are working to resolve:</Text>
      {warnings.map((warning: ReturnWarning, i) => {
        return (
          <Flex flexDirection="column" key={i}>
            <Text> {warning.customerMessage}</Text>
          </Flex>
        );
      })}
    </Flex>
  );
}
