// extracted by mini-css-extract-plugin
export var border = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-border";
export var button = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-button";
export var center = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-center";
export var centered = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-centered";
export var closeButton = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-close-button";
export var disabledWrapper = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-disabled-wrapper";
export var error = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-error";
export var fill = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-fill";
export var footer = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-footer";
export var footerAction = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-footer-action";
export var footerButtons = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-footer-buttons";
export var header = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-header";
export var icon = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-icon";
export var info = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-info";
export var inline = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-inline";
export var large = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-large";
export var main = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-main";
export var modal = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-modal";
export var neutral = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-neutral";
export var none = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-none";
export var scrollable = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-scrollable";
export var small = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-small";
export var success = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-success";
export var tight = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-tight";
export var titleIcon = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-title-icon";
export var warn = "_redotech-redo-web-src-arbiter-components-modal-redo-modal-warn";