import { DraftReturnSchema } from "@redotech/redo-model/draft-return/draft-return";
import { PendingReturnItemSchema } from "@redotech/redo-model/draft-return/draft-return-items";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const AddItemPendingItemSchema = PendingReturnItemSchema.omit({
  id: true,
  manualReview: true,
  flagged: true,
  returnReason: true,
  returnOptions: true,
  selectedReturnOption: true,
});

export type AddItemPendingItem = z.infer<typeof AddItemPendingItemSchema>;

export const addReturnItem = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    previousPendingItemId: z.string().optional(),
    pendingItem: AddItemPendingItemSchema,
  }),
  output: z.object({ draftReturn: DraftReturnSchema }),
};
