import {
  MaybeOriginOrderArrayZodSchema,
  ReturnZodSchema,
} from "@redotech/redo-model/return";
import { z } from "zod";

export enum GetReturnErrorCode {
  ReturnNotFound = "ReturnNotFound",
}
export const getReturn = {
  input: z.object({ returnId: z.string() }),
  output: z.object({
    return: ReturnZodSchema,
    originOrders: MaybeOriginOrderArrayZodSchema,
  }),
};
