import { RouteObject } from "react-router-dom";

export const warrantyPolicyRoutes: RouteObject[] = [
  {
    children: [
      {
        path: "",
        lazy: async () => {
          const { WarrantyPolicyPage } = await import("./warranty-policy-page");
          return { element: <WarrantyPolicyPage /> };
        },
      },
    ],
  },
];
