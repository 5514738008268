import { useLoad } from "@redotech/react-util/load";
import { SettingsContext } from "@redotech/redo-customer-portal-app/contexts/settings";
import { Cart } from "@redotech/shopify-client/storefront.graphql";
import { ReactNode, createContext, useContext, useState } from "react";
import { OrderContext } from "../order/order";
import { handleCreateCart } from "../utils/storefront-cart";

// Uses Partial<Cart> because the cart query in redo/api-server/src/lambda/widgets/cart/get-cart.ts does not request all fields
export interface StorefrontCart extends Partial<Cart> {}

const StorefrontCartContext = createContext<{
  shopifyStorefrontCart: StorefrontCart | undefined;
  setShopifyStorefrontCart: (
    shopifyStorefrontCart: StorefrontCart | undefined,
  ) => void;
}>({ shopifyStorefrontCart: undefined, setShopifyStorefrontCart: () => {} });

const StorefrontCartContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [shopifyStorefrontCart, setShopifyStorefrontCart] = useState<
    StorefrontCart | undefined
  >(undefined);
  const order = useContext(OrderContext);
  const settings = useContext(SettingsContext);

  useLoad(async () => {
    let cart = undefined;
    if (order && settings?.hasStorefrontAccess) {
      cart = await handleCreateCart(settings, order);
    }
    setShopifyStorefrontCart(cart?.id);
  }, [order, settings]);

  return (
    <StorefrontCartContext.Provider
      value={{ shopifyStorefrontCart, setShopifyStorefrontCart }}
    >
      {children}
    </StorefrontCartContext.Provider>
  );
};

export { StorefrontCartContext, StorefrontCartContextProvider };
