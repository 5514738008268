import { Currency } from "@redotech/money/currencies";
import { ReturnItemParams } from "@redotech/redo-customer-portal-shared/util";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { ItemDetail } from "../../shared-components/item-detail";
//Todo support multiple shipments
export function ReturnItems({
  products,
  currency,
}: {
  products: ReturnItemParams[] | undefined;
  currency: Currency;
}) {
  return (
    <Flex flexDirection="column" gap="xl">
      <Text fontSize="lg" fontWeight="semibold">
        Return items
      </Text>
      {products?.map((item, i) => <ItemDetail item={item} key={item.id} />)}
    </Flex>
  );
}
