import { ReactNode, createContext, useState } from "react";

const BackHandlerContext = createContext<{
  backHandler?: () => boolean;
  setBackHandler: React.Dispatch<React.SetStateAction<() => boolean>>;
}>({
  backHandler: () => false,
  setBackHandler: () => (backHandler?: () => boolean) => {},
});

const BackHandlerContextProvider = ({ children }: { children: ReactNode }) => {
  const [backHandler, setBackHandler] = useState(() => () => false);

  return (
    <BackHandlerContext.Provider value={{ backHandler, setBackHandler }}>
      {children}
    </BackHandlerContext.Provider>
  );
};

export { BackHandlerContext, BackHandlerContextProvider };
