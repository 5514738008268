import {
  AddressSchema,
  DraftReturnSchema,
  DraftReturnSource,
} from "@redotech/redo-model/draft-return/draft-return";
import { ReturnTypeEnum } from "@redotech/redo-model/return";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

const CreateDraftReturnBaseInputSchema = z.object({
  returnType: z.nativeEnum(ReturnTypeEnum),
  customerTimezone: z.string().nullish(),
  email: z.string().nullish(),
  address: AddressSchema.nullish(),
});

export type CreateDraftReturnBaseInput = z.infer<
  typeof CreateDraftReturnBaseInputSchema
>;

export const CreateDraftReturnFromOrderInputSchema =
  CreateDraftReturnBaseInputSchema.extend({
    source: z.literal(DraftReturnSource.FROM_ORDER),
    orderId: zExt.objectId().nullish(),
  });

export type CreateDraftReturnFromOrderInput = z.infer<
  typeof CreateDraftReturnFromOrderInputSchema
>;

export const CreateDraftReturnFromWarrantyRegistrationInputSchema =
  CreateDraftReturnBaseInputSchema.extend({
    source: z.literal(DraftReturnSource.FROM_WARRANTY_REGISTRATION),
    warrantyId: zExt.objectId().nullish(),
  });

export type CreateDraftReturnFromWarrantyRegistrationInput = z.infer<
  typeof CreateDraftReturnFromWarrantyRegistrationInputSchema
>;

export const CreateDraftReturnFromProductsInputSchema =
  CreateDraftReturnBaseInputSchema.extend({
    source: z.literal(DraftReturnSource.FROM_PRODUCTS),
    productIds: z.array(z.string()),
    // All draft returns made from only products are third party claims
    // This is a temporary field to support backwards compatibility
    isThirdPartyClaim: z.boolean().optional(),
  });

export type CreateDraftReturnFromProductsInput = z.infer<
  typeof CreateDraftReturnFromProductsInputSchema
>;

const CreateDraftReturnFromSourceInputSchema = z.discriminatedUnion("source", [
  CreateDraftReturnFromOrderInputSchema,
  CreateDraftReturnFromWarrantyRegistrationInputSchema,
  CreateDraftReturnFromProductsInputSchema,
]);

export type CreateDraftReturnFromSourceInput = z.infer<
  typeof CreateDraftReturnFromSourceInputSchema
>;

export const createDraftReturnFromSource = {
  input: CreateDraftReturnFromSourceInputSchema,
  output: z.object({ draftReturn: DraftReturnSchema }),
};
