import { useHandler } from "@redotech/react-util/hook";
import { LoadState, useLoad } from "@redotech/react-util/load";
import {
  getStoreCreditBalance,
  getStoreCreditTransactionHistory,
  StoreCreditBalance,
} from "@redotech/redo-customer-portal-app/api";
import { CustomerContext } from "@redotech/redo-customer-portal-app/contexts/CustomerContext";
import { getPrimaryCustomerEmail } from "@redotech/redo-model/customer";
import { GetStoreCreditTransactionResponseBody } from "@redotech/redo-model/customer-wallet/customer-wallet-definition";
import { createContext, ReactNode, useContext, useState } from "react";

interface StoreCreditContextValue {
  storeCreditTransactionsLoad: LoadState<GetStoreCreditTransactionResponseBody>;
  storeCreditBalanceLoad: LoadState<StoreCreditBalance>;
  reload: () => void;
}
export const StoreCreditContext = createContext<
  StoreCreditContextValue | undefined
>(undefined);

export const StoreCreditContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const customer = useContext(CustomerContext);
  const customerEmail = getPrimaryCustomerEmail(customer);
  const [reloadStoreCreditTrigger, setReloadStoreCreditTrigger] =
    useState(Symbol());

  const storeCreditTransactionsLoad = useLoad(
    async (signal) => {
      if (customerEmail) {
        const storeCreditTransactions = await getStoreCreditTransactionHistory({
          // @ts-expect-error: strict-null-checks
          customerId: customer._id,
          signal,
        });

        return storeCreditTransactions;
      } else {
        return undefined;
      }
    },
    [customer, reloadStoreCreditTrigger],
  );

  const storeCreditBalanceLoad = useLoad(
    async (signal) => {
      if (customerEmail) {
        const storeCreditBalance = await getStoreCreditBalance({
          // @ts-expect-error: strict-null-checks
          customerId: customer._id,
          signal,
        });

        return storeCreditBalance;
      } else {
        return undefined;
      }
    },
    [customer, reloadStoreCreditTrigger],
  );

  const reloadStoreCredit = useHandler(() =>
    setReloadStoreCreditTrigger(Symbol()),
  );

  const value: StoreCreditContextValue = {
    // @ts-expect-error: strict-null-checks
    storeCreditTransactionsLoad,
    // @ts-expect-error: strict-null-checks
    storeCreditBalanceLoad,
    reload: reloadStoreCredit,
  };

  return (
    <StoreCreditContext.Provider value={value}>
      {children}
    </StoreCreditContext.Provider>
  );
};
