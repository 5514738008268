import { ScreenType, useScreenType } from "@redotech/react-util/screen";
import { useItemDetail } from "@redotech/redo-customer-portal-shared/hooks/components/use-item-detail";
import {
  ItemDetailSize,
  ReturnItemParams,
} from "@redotech/redo-customer-portal-shared/util";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { Flex } from "@redotech/redo-web/flex";
import { BadgePillThemeToColorMapping } from "@redotech/redo-web/tags/tag-pill";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import * as classNames from "classnames";
import * as itemDetailCss from "./item-detail.module.css";

export function ItemDetail({
  item,
  isVertical,
  size = ItemDetailSize.MEDIUM,
}: {
  item?: ReturnItemParams;
  isVertical?: boolean;
  size?: ItemDetailSize;
}) {
  const screenType = useScreenType();
  const shouldBeVertical = isVertical ?? screenType === ScreenType.DESKTOP;
  const {
    imageUri,
    texts,
    loading,
    formattedItemValue,
    formattedPrice,
    bonusBadge,
    returnTypeBadges,
  } = useItemDetail({ item, isVertical: shouldBeVertical });

  if (loading) {
    return null;
  }
  const { title, subtitle, textStrings, badgeText, discountExplanation } =
    texts;

  const gap = !shouldBeVertical
    ? "none"
    : size === ItemDetailSize.SMALL
      ? "xs"
      : "md";

  return (
    <Flex flex={1} justify="space-between">
      <Flex gap={shouldBeVertical ? "lg" : "xl"}>
        <Flex>
          <img
            alt={title}
            className={classNames(itemDetailCss.image, {
              [itemDetailCss.small]: size === ItemDetailSize.SMALL,
            })}
            src={imageUri}
          />
        </Flex>
        <Flex>
          <Flex
            dir="column"
            gap={gap}
            justify={shouldBeVertical ? "flex-start" : "center"}
          >
            <Text fontSize="md" fontWeight="medium" textColor="primary">
              {title}
            </Text>

            <Flex dir="column" gap="none">
              {subtitle && (
                <Text
                  fontSize="xs"
                  fontWeight="regular"
                  key="subtitle"
                  textColor="secondary"
                >
                  {subtitle}
                </Text>
              )}
              {textStrings.map((text, index) => (
                <Text
                  fontSize="xs"
                  fontWeight="regular"
                  key={index}
                  textColor="secondary"
                >
                  {text}
                </Text>
              ))}

              {returnTypeBadges?.length ? (
                <Flex
                  dir="row"
                  gap="sm"
                  py={shouldBeVertical ? "md" : "xs"}
                  wrap="wrap"
                >
                  {bonusBadge ? (
                    <RedoBadge
                      color={BadgePillThemeToColorMapping[bonusBadge.color]}
                      text={bonusBadge.text}
                    />
                  ) : null}
                  {badgeText ? (
                    <RedoBadge color="success" text={badgeText} />
                  ) : null}
                  {returnTypeBadges.map((badge, index) => (
                    <RedoBadge
                      color={BadgePillThemeToColorMapping[badge.color]}
                      key={index}
                      text={badge.text}
                    />
                  ))}
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {shouldBeVertical ? (
        <Flex dir="row" gap="sm">
          {formattedPrice && (
            <Tooltip placement="top" title={discountExplanation}>
              <span>
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  textColor="quaternary"
                  textDecoration="line-through"
                >
                  {formattedPrice}
                </Text>
              </span>
            </Tooltip>
          )}
          <Text fontSize="sm" fontWeight="medium" textColor="primary">
            {formattedItemValue}
          </Text>
        </Flex>
      ) : null}
    </Flex>
  );
}
