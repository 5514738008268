import { AddressInfo } from "@redotech/redo-customer-portal-shared/hooks/useAddress/use-address";
import { Address as RedoAddress } from "@redotech/redo-model/draft-return/draft-return";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { filterTruthy } from "@redotech/util/array";
import { memo } from "react";

export function Address({
  addressInfo,
  pickupNotAvailable,
}: {
  addressInfo: AddressInfo;
  pickupNotAvailable?: boolean;
}) {
  return (
    <Flex
      borderColor="secondary"
      borderStyle="solid"
      borderWidth="1px"
      justify="space-between"
      px="2xl"
      py="xl"
      radius="md"
    >
      <Flex flexDirection="column" gap="lg">
        <Flex>
          <Text fontSize="sm" fontWeight="semibold" textColor="primary">
            {addressInfo.title}
          </Text>
        </Flex>
        <Flex dir="column" gap="xs">
          <Text fontSize="sm" fontWeight="thin" textColor="secondary">
            {addressInfo.addressText1}
          </Text>
          {!!addressInfo.addressText2 && (
            <Text fontSize="sm" fontWeight="thin" textColor="secondary">
              {addressInfo.addressText2}
            </Text>
          )}
          {!!addressInfo.addressText3 && (
            <Text fontSize="sm" fontWeight="thin" textColor="secondary">
              {addressInfo.addressText3}
            </Text>
          )}
          {!!addressInfo.addressText4 && (
            <Text fontSize="sm" fontWeight="thin" textColor="secondary">
              {addressInfo.addressText4}
            </Text>
          )}
          {!!addressInfo.addressText5 && (
            <Text fontSize="sm" fontWeight="thin" textColor="secondary">
              {addressInfo.addressText5}
            </Text>
          )}
          {pickupNotAvailable === true && addressInfo.address && (
            <Text fontSize="sm" textColor="secondary">
              Pickup is not available at this address
            </Text>
          )}
        </Flex>
      </Flex>
      {addressInfo.edit && (
        <Flex align="center" justify="center">
          <RedoButton
            hierarchy="secondary"
            onClick={addressInfo.edit}
            size="md"
            text="Edit"
          />
        </Flex>
      )}
    </Flex>
  );
}

export const ShippingLineOfText = memo(function ShippingLineOfText({
  lineOfText,
}: {
  lineOfText: string;
}) {
  return (
    <Text fontSize="sm" fontWeight="thin" textColor="secondary">
      {lineOfText}
    </Text>
  );
});

export const AddressOld = memo(function Address({
  address,
  title,
  pickupNotAvailable,
  edit,
}: {
  address?: RedoAddress;
  title: string;
  pickupNotAvailable?: boolean;
  edit?: () => void;
}) {
  let secondLine = "";
  if (address) {
    secondLine = filterTruthy([address.city, address.state]).join(", ");
    if (address.zip) {
      secondLine += ` ${address.zip}`;
    }
  }
  return (
    <Flex
      dir="column"
      gap="md"
      style={{ borderColor: "#E5E5E5", borderWidth: 1, borderRadius: 12 }}
    >
      <Text fontSize="sm" fontWeight="semibold" textColor="primary">
        {title}
      </Text>
      {address && (
        <Flex dir="column" gap="none" pt="sm">
          <ShippingLineOfText lineOfText={address.name} />
          <ShippingLineOfText lineOfText={address.street1} />
          <ShippingLineOfText lineOfText={address.street2 || ""} />
          <ShippingLineOfText lineOfText={secondLine} />
          <ShippingLineOfText lineOfText={address.country} />
        </Flex>
      )}
      {!address && (
        <ShippingLineOfText lineOfText="Same as returning items from." />
      )}
      {pickupNotAvailable === true && address && (
        <ShippingLineOfText lineOfText="Pickup is not available at this address" />
      )}
      {edit && (
        <RedoButton hierarchy="tertiary" onClick={() => edit()} text="Edit" />
      )}
    </Flex>
  );
});
