import { z } from "zod";

export enum ConvertTokenError {
  ExpiredToken = "ExpiredToken",
  InvalidToken = "InvalidToken",
}
export const convertTokenToCustomerAuth = {
  input: z.object({
    emailToken: z.string().nullish(),
    orderTrackingToken: z.string().nullish(),
  }),
  output: z.object({
    customerAuthToken: z.string(),
    customer: z.object({
      name: z.string(),
      email: z.string(),
      customerId: z.string(),
    }),
    orderId: z.string().nullish(),
  }),
};
