import {
  addDiscountCodesToCart,
  recreateExpiredDiscountCodes,
  removeItemsFromCart,
  updateCartItemQuantity,
} from "@redotech/redo-customer-portal-app/api";
import { ReturnAppSettings } from "@redotech/redo-customer-portal-shared/settings";
import type {
  Order,
  OrderWithReturnableItems,
} from "@redotech/redo-model/order";
import { ReturnableItem } from "@redotech/redo-model/return-flow";
import {
  CartLineInput,
  PreferenceDeliveryMethodType,
} from "@redotech/shopify-client/storefront.graphql";
import {
  ShopifyStorefrontClient,
  addLineItemToCart,
  createCart,
  getCart,
} from "@redotech/shopify-storefront";

async function handleExpiredDiscountCodes(
  settings: ReturnAppSettings,
  currentOrder: Order,
) {
  if (settings.exchanges.recreateExpiredDiscounts) {
    const codes = currentOrder?.shopify.discount_codes
      ?.filter((code) => code.type !== "shipping") // no need to re-create shipping discounts
      ?.map((code) => code.code);
    await recreateExpiredDiscountCodes({ codes, storeUrl: settings.storeUrl });
  }
}

export const createNewCart = async (
  settings: ReturnAppSettings,
  currentOrder: OrderWithReturnableItems,
) => {
  const storefrontClient = new ShopifyStorefrontClient(
    settings.storeUrl,
    settings.storefrontAccessToken,
  );
  const cartId = await createCart(storefrontClient, {
    email: currentOrder?.shopify?.customer?.email || null,
    countryCode: currentOrder?.shopify.shipping_address
      ? currentOrder.shopify.shipping_address.country_code
      : null,
    preferences: {
      delivery: {
        // Ensures shipping costs are not included
        deliveryMethod: [PreferenceDeliveryMethodType.PickUp],
      },
    },
  });

  return cartId;
};

export const handleCreateCart = async (
  settings: ReturnAppSettings,
  currentOrder: OrderWithReturnableItems,
) => {
  if (!settings.exchanges.usePreDiscountPrice) {
    try {
      await handleExpiredDiscountCodes(settings, currentOrder);
    } catch (e) {
      console.error(`Could not recreate expired discount codes: ${e}`);
    }
  }

  const cartID = await createNewCart(settings, currentOrder);

  if (!settings.exchanges.usePreDiscountPrice) {
    // if discount codes exist add them to cart.
    let discountCodes =
      currentOrder?.shopify.discount_codes?.map((code: any) => code.code) || [];
    // Remove redo discount codes from an exchange - return on a return
    discountCodes = discountCodes.filter(
      (code: string) => code !== "Exchange Credit",
    );
    const automaticDiscounts: string[] = [];
    for (const discountApplication of currentOrder?.shopify
      .discount_applications || []) {
      if (discountApplication.type === "automatic") {
        automaticDiscounts.push(discountApplication.title);
      }
    }
    await addDiscountCodesToCart({
      storeUrl: settings.storeUrl,
      cartId: cartID,
      discountCodes,
      automaticDiscounts,
    });
  }

  return { id: cartID };
};

export const getCartItemQuantities = (
  itemsReadyForReturn: ReturnableItem[],
  cartItem: any,
) => {
  const matchingVariantExchanges = itemsReadyForReturn.filter(
    (item: ReturnableItem) =>
      item.lineItemSource?.new_item_id === cartItem.node.merchandise.id,
  );
  const variantExchangeQuantity = matchingVariantExchanges.length;
  const advancedExchangeQuantity =
    cartItem.node.quantity - variantExchangeQuantity;
  return { variantExchangeQuantity, advancedExchangeQuantity };
};

export const removeAdvancedExchangeItemsFromCart = async (
  shopifyStorefrontCart: any,
  itemsReadyForReturn: ReturnableItem[],
  settings: ReturnAppSettings,
) => {
  for (const cartItem of shopifyStorefrontCart?.lines?.edges || []) {
    const { variantExchangeQuantity, advancedExchangeQuantity } =
      getCartItemQuantities(itemsReadyForReturn, cartItem);
    if (!advancedExchangeQuantity) {
      continue;
    }
    if (variantExchangeQuantity) {
      await updateCartItemQuantity({
        storeUrl: settings.storeUrl,
        cartId: shopifyStorefrontCart.id,
        itemId: cartItem.node.id,
        quantity: advancedExchangeQuantity,
      });
    } else {
      await removeItemsFromCart({
        storeUrl: settings.storeUrl,
        cartId: shopifyStorefrontCart.id,
        lineIds: [cartItem.node.id],
      });
    }
  }
};

export const removeVariantExchangeItemFromCart = async (
  shopifyStorefrontCart: any,
  settings: ReturnAppSettings,
  graphqlID: string,
) => {
  const cartItem = shopifyStorefrontCart.lines.edges.find(
    (item: any) => item.node.merchandise.id === graphqlID,
  );
  if (!cartItem) {
    console.error(`Could not find item with id ${graphqlID} in cart`);
    return;
  }
  if (cartItem.node.quantity > 1) {
    await updateCartItemQuantity({
      storeUrl: settings.storeUrl,
      cartId: shopifyStorefrontCart.id,
      itemId: cartItem.node.id,
      quantity: cartItem.node.quantity - 1,
    });
  } else {
    await removeItemsFromCart({
      storeUrl: settings.storeUrl,
      cartId: shopifyStorefrontCart.id,
      lineIds: [cartItem.node.id],
    });
  }
};

export const addMerchandiseToCart = async (
  settings: ReturnAppSettings,
  cartId: string,
  cartLines: CartLineInput[],
) => {
  const storefrontClient = new ShopifyStorefrontClient(
    settings.storeUrl,
    settings.storefrontAccessToken,
  );

  await addLineItemToCart(storefrontClient, cartId, cartLines);
};

export const getCartCheckoutUrl = async (
  settings: ReturnAppSettings,
  cartId: string,
) => {
  const storefrontClient = new ShopifyStorefrontClient(
    settings.storeUrl,
    settings.storefrontAccessToken,
  );

  const cart = await getCart(storefrontClient, cartId);
  return cart.checkoutUrl;
};
