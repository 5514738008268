// extracted by mini-css-extract-plugin
export var account = "_redotech-redo-return-app-src-order-page-account";
export var accountDropdown = "_redotech-redo-return-app-src-order-page-account-dropdown";
export var accountMenuList = "_redotech-redo-return-app-src-order-page-account-menu-list";
export var accountMenuListItem = "_redotech-redo-return-app-src-order-page-account-menu-list-item";
export var accountText = "_redotech-redo-return-app-src-order-page-account-text";
export var avatar = "_redotech-redo-return-app-src-order-page-avatar";
export var avatarContainer = "_redotech-redo-return-app-src-order-page-avatar-container";
export var backArrow = "_redotech-redo-return-app-src-order-page-back-arrow";
export var brand = "_redotech-redo-return-app-src-order-page-brand";
export var brandContainer = "_redotech-redo-return-app-src-order-page-brand-container";
export var cart = "_redotech-redo-return-app-src-order-page-cart";
export var cartContainer = "_redotech-redo-return-app-src-order-page-cart-container";
export var cartSizeBubble = "_redotech-redo-return-app-src-order-page-cart-size-bubble";
export var cartTotalText = "_redotech-redo-return-app-src-order-page-cart-total-text";
export var chevronDown = "_redotech-redo-return-app-src-order-page-chevron-down";
export var grayText = "_redotech-redo-return-app-src-order-page-gray-text";
export var hiddenButAccessible = "_redotech-redo-return-app-src-order-page-hidden-but-accessible";
export var imageWrapper = "_redotech-redo-return-app-src-order-page-image-wrapper";
export var logo = "_redotech-redo-return-app-src-order-page-logo";
export var main = "_redotech-redo-return-app-src-order-page-main";
export var nameText = "_redotech-redo-return-app-src-order-page-name-text";
export var navBar = "_redotech-redo-return-app-src-order-page-nav-bar";
export var navBarContent = "_redotech-redo-return-app-src-order-page-nav-bar-content";
export var navBarLogo = "_redotech-redo-return-app-src-order-page-nav-bar-logo";
export var page = "_redotech-redo-return-app-src-order-page-page";
export var pageWrapper = "_redotech-redo-return-app-src-order-page-page-wrapper";
export var sideButtons = "_redotech-redo-return-app-src-order-page-side-buttons";
export var storeReturn = "_redotech-redo-return-app-src-order-page-store-return";
export var storeReturnBlock = "_redotech-redo-return-app-src-order-page-store-return-block";
export var textDanger = "_redotech-redo-return-app-src-order-page-text-danger";
export var textSuccess = "_redotech-redo-return-app-src-order-page-text-success";