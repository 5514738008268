import { SocialLoginOption } from "@redotech/redo-model/auth/social-auth";
import { z } from "zod";

export const convertSocialAuthToCustomerAuth = {
  input: z.object({
    provider: z.nativeEnum(SocialLoginOption),
    code: z.string(),
  }),
  output: z.object({
    error: z.string().nullable(),
    customerAuthToken: z.string().optional(),
    customer: z
      .object({ name: z.string(), email: z.string(), customerId: z.string() })
      .optional(),
    orderId: z.string().optional(),
    warrantyId: z.string().optional(),
  }),
};
