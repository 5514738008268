import { GetReturnErrorCode } from "@redotech/customer-portal-sdk/rpc/schema/get-return";
import { Currency } from "@redotech/money/currencies";
import { useLoad } from "@redotech/react-util/load";
import { getResource } from "@redotech/redo-model/localization/resource";
import {
  allProductsAreRepair,
  ReturnAddressZod,
  ReturnWarning,
  ReturnZod,
} from "@redotech/redo-model/return";
import { ReturnStatus } from "@redotech/redo-model/return-status";
import {
  getReturnTotalsFrontendLogFn,
  ReturnTotals,
  ReturnTotalsCalculator,
} from "@redotech/redo-model/return-totals-calculator";
import { returnToTrackable } from "@redotech/redo-model/trackable";
import { filterTruthy } from "@redotech/util/array";
import { assertNever } from "@redotech/util/type";
import { useContext, useMemo } from "react";
import { RpcClientContext } from "../../contexts/rpc";
import { ReturnAddressType } from "../../navigator";
import { ReturnAppSettings } from "../../settings";
import {
  ReturnAddressToAddress,
  ReturnItemParams,
  subtitleWithoutDefault,
} from "../../util";
import { AddressInfo } from "../useAddress/use-address";

import { useCreationStatus } from "./use-creation-status";
import { useSteps, UseStepsResponse } from "./use-steps";
export enum TopStatus {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

export enum ReturnSubmissionResultStatus {
  CREATED_RETURN = "successfully_created_return",
  CREATED_RETURN_WITH_ERRORS = "created_return_with_errors",
  REJECTED_RETURN = "rejected_return",
}

export type ErrorCodeInfo =
  | {
      type: GetReturnErrorCode.ReturnNotFound;
      data: { texts: { title: string } };
    }
  | undefined;
interface ReturnConfirmationResponse extends UseStepsResponse {
  returnHeader: {
    title: string;
    subtitle: string;
    onClick: (() => void) | undefined;
    onClickTitle: string | undefined;
    status: TopStatus;
  };
  error: string | undefined;
  errorCodeData: ErrorCodeInfo | undefined;
  loadingReturn: boolean;
  rejected: boolean;
  rejectHtml: string;
  customSuccessMessage: string | undefined;
  warnings: ReturnWarning[] | undefined;
  deadlineDays: number;
  showDeadline: boolean | undefined;
  newItems: ReturnItemParams[] | undefined;
  products: ReturnItemParams[] | undefined;
  productsZod: ReturnZod["products"] | undefined;
  currency: Currency;
  newItemsHeader: string;
  showAddresses: boolean;
  newOrderAddress: AddressInfo | undefined;
  returnAddress: AddressInfo | undefined;
  returnTotals: ReturnTotals | undefined;
  summaryFeesHeader: string;
  summaryFeesOrderHeader: string;
  draftOrderUrl: string | undefined;
  texts: { rejectedText: string; returnItems: string; newItems: string };
  labelDeducted: boolean | undefined;
  showCreationStatus: boolean;
  shouldShowSummary: boolean;
}

export const useReturnConfirmation = ({
  settings,
  returnId,
  navigateToCheckout,
}: {
  settings: ReturnAppSettings | undefined;
  returnId: string;
  navigateToCheckout: (orderUrl: string) => void;
}): ReturnConfirmationResponse => {
  const client = useContext(RpcClientContext);

  const creationStatus = useCreationStatus({ returnId });

  const returnLoad = useLoad(
    async (signal) => {
      try {
        if (!client || !creationStatus.completelyFinished) {
          return { returnData: undefined, newItems: [], orders: undefined };
        }
        const { return: returnData, originOrders } = await client.getReturn({
          returnId: returnId,
        });
        const { orders } = await client.getOrders({
          orderIds: returnData.orders.map((order) => order.order),
        });

        const newItems: ReturnItemParams[] =
          returnData.advancedExchangeItems.map((item) => ({
            id: "",
            imageSrc: item.images?.[0] ?? "",
            title: item.title ?? "",
            subtitle: item.variantTitle ?? "",
            quantity: item.quantity ?? 1,
            variants: [],
            formattedPrice: item.price ?? "0",
            isSelected: true,
            currency: returnData.currency ?? Currency.USD,
            price: item.price ?? "0",
            itemValue: item.price ?? "0",
          }));
        returnData.products.forEach((product) => {
          if (product.exchange_for) {
            const price = product.exchange_for.price ?? "0";
            newItems.push({
              id: "",
              imageSrc: product.exchange_for.images?.[0] ?? "",
              title: product.exchange_for.product_title,
              subtitle: product.exchange_for.variant_title,
              quantity: product.quantity ?? 1,
              variants: [],
              formattedPrice: price,
              isSelected: true,
              currency: returnData.currency ?? Currency.USD,
              price: price,
              itemValue: price,
            });
          }
          if (product.exchangeGroupItem) {
            const price = product.exchangeGroupItem.price ?? "0";
            newItems.push({
              id: "",
              imageSrc: product.exchangeGroupItem.imageSrc ?? "",
              title: product.exchangeGroupItem.title,
              subtitle: product.exchangeGroupItem.variantTitle,
              quantity: product.quantity ?? 1,
              variants: [],
              formattedPrice: price,
              isSelected: true,
              currency: returnData.currency ?? Currency.USD,
              price: price,
              itemValue: price,
            });
          }
        });
        // Filter our irrelavant warnings
        returnData.warnings = returnData.warnings?.filter(
          (warning: ReturnWarning) => {
            if (warning.type === "label" && returnData.shipment) {
              return false;
            } else if (
              warning.type === "pickup" &&
              returnData.shipment?.pickup
            ) {
              return false;
            } else if (
              warning.type === "instant-refund" &&
              returnData.instantRefund
            ) {
              return false;
              // Claim report warnings are internal and should not be shown to the customer
            } else if (warning.type === "claim-report") {
              return false;
            }
            return true;
          },
        );
        return { returnData, newItems, orders: orders, originOrders };
      } catch (e: any) {
        if (e?.code) {
          const returnErrorCode = e.code as GetReturnErrorCode;
          switch (returnErrorCode) {
            case GetReturnErrorCode.ReturnNotFound:
              throw e;
            default:
              assertNever(returnErrorCode);
          }
        }
        if (e?.message?.includes("Output validation")) {
          throw new Error(
            `Validation Error: Your return was submitted successfully, but there was an issue parsing your return, please contact support at support@getredo.com with the return id ${returnId}`,
          );
        }
        throw new Error(
          `Your return was submitted successfully, but there was an issue loading your return. Please contact support at support@getredo.com with the return id ${returnId}.`,
        );
      }
    },
    [client, returnId, creationStatus.completelyFinished],
  );

  const { returnData, orders, newItems, originOrders } = returnLoad.value ?? {};
  const rejected = returnData?.status === ReturnStatus.REJECTED;

  const returnTotals = useMemo(() => {
    if (!returnData || !settings || !orders) {
      return undefined;
    }
    const calculator = new ReturnTotalsCalculator({
      return_: returnData as any, //TODO type this
      order: orders[0] as any,
      orders: orders as any,
      team: { settings },
      originOrders: originOrders ?? [{ error: "No origin order" }],
      logFn: getReturnTotalsFrontendLogFn(),
    });
    const rawTotals = calculator.getTotalsForAllProducts();

    //I'm copying and pasting this, but this should be fixed
    const isInstantRefund =
      returnData.totals.refund === rawTotals.totalRefundWithoutShipping * 0.97;

    const returnTotals = calculator.getTotalsForAllProducts(isInstantRefund);

    return returnTotals;
  }, [orders, returnData, settings, originOrders]);

  const copyResource = getResource({
    variant: returnData?.type || "return",
    overrides: settings?.resourceOverride,
  });

  const returnSubmissionResultStatus = useMemo(() => {
    if (!returnData) {
      return undefined;
    } else if (rejected) {
      return ReturnSubmissionResultStatus.REJECTED_RETURN;
    } else if (returnData.warnings?.length) {
      return ReturnSubmissionResultStatus.CREATED_RETURN_WITH_ERRORS;
    } else {
      return ReturnSubmissionResultStatus.CREATED_RETURN;
    }
  }, [rejected, returnData]);

  const needToPayDraftOrder =
    returnData?.type === "return" &&
    returnData.draftOrderURL &&
    !returnData.exchangeOrder.length;

  const returnHeader = useMemo(() => {
    const successSubtitle =
      "We have received your submission. Your return will be processed soon.";

    if (needToPayDraftOrder) {
      return {
        title: "Your return submission is almost complete",
        subtitle:
          "Pay for your exchange to receive your return label and complete your submission.",
        onClick: () => navigateToCheckout(returnData?.draftOrderURL ?? ""),
        onClickTitle: "Pay for exchange order",
        status: TopStatus.WARNING,
      };
    }
    switch (returnSubmissionResultStatus) {
      case ReturnSubmissionResultStatus.CREATED_RETURN:
        return {
          title: copyResource.CONFIRMATION_HEADER_TEXT_SUBMIT,
          subtitle: successSubtitle,
          onClick: undefined,
          onClickTitle: undefined,
          status: TopStatus.SUCCESS,
        };
      case ReturnSubmissionResultStatus.CREATED_RETURN_WITH_ERRORS:
        return {
          title: copyResource.CONFIRMATION_HEADER_TEXT_SUBMIT_WITH_ERRORS,
          subtitle: successSubtitle,
          onClick: undefined,
          onClickTitle: undefined,
          status: TopStatus.ERROR,
        };
      case ReturnSubmissionResultStatus.REJECTED_RETURN:
        return {
          title: copyResource.CONFIRMATION_HEADER_TEXT_REJECT,
          subtitle: successSubtitle,
          onClick: undefined,
          onClickTitle: undefined,
          status: TopStatus.ERROR,
        };
      default:
        return {
          title: "Something went wrong",
          subtitle: "Please exit out and try again.",
          onClick: undefined,
          onClickTitle: undefined,
          status: TopStatus.ERROR,
        };
    }
  }, [
    navigateToCheckout,
    returnSubmissionResultStatus,
    copyResource,
    needToPayDraftOrder,
    returnData?.draftOrderURL,
  ]);

  const trackable =
    returnData && orders && orders[0]
      ? returnToTrackable(returnData as any, orders[0] as any) //TODO, eventually type this function
      : undefined;

  const stepData = useSteps({ settings, trackable });

  const deadlineDays = Math.max(
    0,
    Math.round(
      Temporal.Now.instant()
        // FIXME Our types are all wonky - expirationDate is really a string, not a Date object
        .until(
          returnData?.expirationDate.toTemporalInstant() ??
            Temporal.Now.instant(),
        )
        .total("days"),
    ),
  );
  const showDeadline =
    returnData?.products.some((product) => !product.green_return) &&
    returnData?.expirationDate &&
    deadlineDays <= 10;

  const isExchange =
    returnData?.advancedExchangeItems?.length ||
    returnData?.products.some(
      (product) => product.exchange_for || product.exchangeGroupItem,
    );

  const showAddresses = !!returnData?.shipping_address;
  const showNewOrderAddress =
    showAddresses && !!isExchange && !!returnData.exchangeOrder.length;

  function addressToInfo(
    address: ReturnAddressZod,
    title: string,
    type: ReturnAddressType,
  ): AddressInfo {
    return {
      address: ReturnAddressToAddress(address),
      title: title,
      addressText1: address.name || "",
      addressText2: address.address1,
      addressText3: address.address2 ?? undefined,
      addressText4: filterTruthy([
        address.city,
        address.province,
        address.zip,
      ]).join(", "),
      type: type,
    };
  }
  const returnAddress: AddressInfo | undefined = returnData?.shipping_address
    ? addressToInfo(
        returnData.shipping_address,
        "Return items to:",
        ReturnAddressType.SHIPPING,
      )
    : undefined;

  let newOrderAddressInfo: AddressInfo | undefined;
  if (showNewOrderAddress) {
    if (returnData.newOrderAddress) {
      newOrderAddressInfo = addressToInfo(
        returnData.newOrderAddress,
        "Shipping new items to:",
        ReturnAddressType.NEW_ORDER,
      );
    } else if (returnData.shipping_address) {
      newOrderAddressInfo = addressToInfo(
        returnData.shipping_address,
        "Shipping new items to:",
        ReturnAddressType.NEW_ORDER,
      );
    }
  }

  const errorCode = returnLoad.error?.code as GetReturnErrorCode | undefined;

  let errorCodeData: ErrorCodeInfo | undefined;
  switch (errorCode) {
    case GetReturnErrorCode.ReturnNotFound:
      errorCodeData = {
        type: GetReturnErrorCode.ReturnNotFound,
        data: { texts: { title: "We we're unable to locate your return." } },
      };
      break;
    case undefined:
      errorCodeData = undefined;
      break;
    default:
      assertNever(errorCode);
  }
  return {
    ...stepData,
    returnHeader,
    error: returnLoad.error?.message as string | undefined,
    errorCodeData: errorCodeData,
    loadingReturn: returnLoad.pending || returnLoad.value === undefined,
    rejected,

    rejectHtml: returnData?.products[0]?.rejectMessage ?? "",
    customSuccessMessage: settings?.theme.custom_confirmation_text,
    warnings: returnData?.warnings,
    deadlineDays,
    showDeadline,
    newItems: newItems,
    products: returnData?.products.map(
      function toParams(product): ReturnItemParams {
        return {
          id: product._id,
          imageSrc: product.images[0] ?? "",
          title: product.product_title,
          subtitle: subtitleWithoutDefault(product.variant_title),
          quantity: product.quantity,
          variants: [],
          formattedPrice: product.price,
          isSelected: true,
          currency: returnData?.currency ?? Currency.USD,
          price: product.price,
          itemValue: product.price,
        };
      },
    ),
    productsZod: returnData?.products,
    currency: returnData?.currency ?? Currency.USD,
    newItemsHeader: copyResource.ITEMS_NEW_HEADER,
    showAddresses,
    newOrderAddress: newOrderAddressInfo,
    returnAddress: returnAddress,
    returnTotals,
    summaryFeesHeader: copyResource.FEES_HEADER,
    summaryFeesOrderHeader: copyResource.FEES_ORDER_HEADER,
    draftOrderUrl: returnData?.draftOrderURL,
    texts: {
      rejectedText: "This item was rejected",
      returnItems: "Return Details",
      newItems: "New items",
    },
    labelDeducted: returnData?.labelDeductedFromCredit ?? undefined,
    showCreationStatus: !creationStatus.completelyFinished,
    shouldShowSummary: !allProductsAreRepair(returnData?.products),
  };
};
