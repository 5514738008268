import type { CustomerPortalRpcClient } from "@redotech/customer-portal-sdk/rpc/client";
import { useLoad } from "@redotech/react-util/load";
import { RpcClientContext } from "@redotech/redo-customer-portal-shared/contexts/rpc";
import { createContext, useContext } from "react";
import { SettingsContext } from "./settings";

export const FaqContext = createContext<
  Awaited<ReturnType<CustomerPortalRpcClient["getFaq"]>> | undefined
>(undefined);

export function FaqContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const client = useContext(RpcClientContext);
  const settings = useContext(SettingsContext);

  const faqLoad = useLoad(async () => {
    if (!client || !settings?.support?.enabled) {
      return null;
    }

    const response = await client.getFaq({});

    return response;
  }, [client, settings]);

  return (
    <FaqContext.Provider value={faqLoad.value ?? undefined}>
      {children}
    </FaqContext.Provider>
  );
}
