import { DraftReturnSchema } from "@redotech/redo-model/draft-return/draft-return";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import {
  CreateDraftReturnFromOrderInputSchema,
  CreateDraftReturnFromProductsInputSchema,
  CreateDraftReturnFromWarrantyRegistrationInputSchema,
} from "./create-draft-return-from-source";

const CreateOrderlessDraftReturnInputSchema = z
  .object({
    ...CreateDraftReturnFromWarrantyRegistrationInputSchema.shape,
    ...CreateDraftReturnFromProductsInputSchema.shape,
  })
  .omit({ source: true });

export const createDraftReturn = {
  input: CreateDraftReturnFromOrderInputSchema.omit({ source: true }),
  output: z.object({ draftReturn: DraftReturnSchema }),
};

export const createOrderlessDraftReturn = {
  input: CreateOrderlessDraftReturnInputSchema,
  output: z.object({ draftReturn: DraftReturnSchema }),
};

export const addProductToOrderlessDraftReturn = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    productIds: z.array(z.string()),
  }),
  output: z.object({ draftReturn: DraftReturnSchema }),
};
