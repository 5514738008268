import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
export function DeadlineCard({ deadlineDays }: { deadlineDays: number }) {
  return (
    <Flex flexDirection="column" gap="sm">
      <Text fontWeight="bold">Deadline</Text>
      <Text>
        You must ship your package within {deadlineDays} day
        {deadlineDays !== 1 && "s"}.
      </Text>
    </Flex>
  );
}
