import TinyColor from "@ctrl/tinycolor";
import { useLoad } from "@redotech/react-util/load";
import { useStyleProperty } from "@redotech/react-util/style";
import { ReturnAppSettings } from "@redotech/redo-customer-portal-shared/settings";
import { returnFlowJsonFormat } from "@redotech/redo-model/return-flow";
import { CustomerPortalVersion } from "@redotech/redo-model/team";
import { Flex } from "@redotech/redo-web/flex";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { ShopifyStorefrontClient } from "@redotech/shopify-storefront";
import { ReactNode, createContext, memo } from "react";
import { createPortal } from "react-dom";
import { useSearchParams } from "react-router-dom";
import { getSettings } from "../api";

export const SettingsContext = createContext<ReturnAppSettings | undefined>(
  undefined,
);
export const StorefrontClientContext =
  createContext<ShopifyStorefrontClient | null>(null);

export const SettingsProvider = memo(function SettingsProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const [searchParams, _] = useSearchParams();
  const previewNewLogin = searchParams.get("previewNewLogin") === "true";

  const settingsLoad = useLoad(async () => {
    const response = await getSettings();
    const data = response.data;
    let storefrontClient: ShopifyStorefrontClient | null = null;
    if (data.storefrontAccessToken) {
      storefrontClient = new ShopifyStorefrontClient(
        data.storeUrl,
        data.storefrontAccessToken,
      );
    }
    const settings: ReturnAppSettings = {
      theme: {
        button_background_color: data.theme.button_color,
        button_text_color:
          TinyColor(data.theme.button_color).getBrightness() < 200
            ? "#ffffff"
            : "#000000",
        button_color_dark:
          TinyColor(data.theme.button_color).getBrightness() < 200,
        accent_color: data.theme.accent_color,
        accent_background_color: data.theme.accent_background_color,
        accent_text_color:
          TinyColor(data.theme.accent_color).getBrightness() < 200
            ? "#ffffff"
            : "#000000",
        home_text_color: data.theme.home_text_color,
        background_image: data.theme.background_url || "",
        logo_image: data.settings.brandKit?.images.logoUrl || "",
        enable_alert: data.theme.enable_alert,
        notification: data.theme.notification,
        returnButtonText: data.theme.returnButtonText,
        claimButtonText: data.theme.claimButtonText,
        standardExchangeText: data.theme.standard_exchange_text,
        instantExchangeText: data.theme.instant_exchange_text,
        exchangeButtonText: data.theme.exchangeButtonText,
        notExchangeButtonText: data.theme.notExchangeButtonText,
        privacy_link: data.theme.privacy_link,
        body_font_family: data.theme.body_font_family,
        title_font_family: data.theme.title_font_family,
        custom_css: data.theme.custom_css,
        new_custom_css: data.theme.new_custom_css,
        shippingFeeText: data.theme.shippingFeeText,
        custom_confirmation_text: data.theme.custom_confirmation_text,
        custom_payment_placeholder_color:
          data.theme.custom_payment_placeholder_color,
        green_return_text: data.theme.green_return_text,
        hideDiscountCodes: data.theme.hideDiscountCodes,
        hideSuggestedProducts: data.theme.hideSuggestedProducts,
        green_return_confirmation_text:
          data.theme.green_return_confirmation_text,
        green_return_confirmation_description:
          data.theme.green_return_confirmation_description,
        cantFindOrderText: data.theme.cantFindOrderText,
        claimSummarySubtext: data.theme.claimSummarySubtext,
        warrantySummarySubtext: data.theme.warrantySummarySubtext,
        returnSummarySubtext: data.theme.returnSummarySubtext,
        hideOrderStatusUrl: data.theme.hideOrderStatusUrl,
        hideRateYourExperienceWidget: data.theme.hideRateYourExperienceWidget,
        hideLoginOrderNumberTooltip: data.theme.hideLoginOrderNumberTooltip,
        collapseVariantThreshold: data.theme.collapseVariantThreshold,
        merchantDisplayName: data.theme.merchantDisplayName,
        useBetaPackagePickupModal: data.theme.useBetaPackagePickupModal,
        socialLoginOptions: data.theme.socialLoginOptions,
      },
      customerEmail: data.customerEmail,
      merchantName: data.name,
      merchantAddress: data.address,
      discountDistributionMethod: data.settings.discountDistributionMethod,
      customInStoreAddresses: data.customInStoreAddresses,
      returns: data.settings.returns,
      packageProtection: data.settings.packageProtection,
      warranties: data.settings.warranties,
      hasStorefrontAccess: data.hasStorefrontAccess,
      storefrontAccessToken: data.storefrontAccessToken || "",
      headlessAccessToken: data.headlessAccessToken,
      locations: data.settings.locations,
      returnInStoreEnabled: data.settings.returnInStoreEnabled,
      inStoreDropOffPackingSlipEnabled:
        data.settings.inStoreDropOffPackingSlipEnabled,
      customerAccounts: {
        ...data.settings.customerAccounts,
        enabled: data.settings.customerAccounts?.enabled ?? false,
        widgetSettings: {
          accountTabSections:
            data.settings.customerWidget?.customerAccounts
              ?.accountTabSections || [],
        },
      },
      storeUrl: data.storeUrl,
      exchanges: data.settings.exchanges,
      inventory: data.settings.inventory,
      returnFlow: data.settings.returnFlow
        ? returnFlowJsonFormat.read(data.settings.returnFlow)
        : { steps: [] },
      claimFlow: data.settings.claimFlow
        ? returnFlowJsonFormat.read(data.settings.claimFlow)
        : { steps: [] },
      finalizeReturnFlow: data.settings.finalizeReturnFlow
        ? returnFlowJsonFormat.read(data.settings.finalizeReturnFlow)
        : { steps: [] },
      finalizeClaimFlow: data.settings.finalizeClaimFlow
        ? returnFlowJsonFormat.read(data.settings.finalizeClaimFlow)
        : { steps: [] },
      warrantyFlow: data.settings.warrantyFlow
        ? returnFlowJsonFormat.read(data.settings.warrantyFlow)
        : { steps: [] },
      active: data.service_active,
      returnAdjustment: data.settings.returnAdjustment,
      coverage: data.settings.coverage,
      merchantCoverage: data.settings.merchantCoverage,
      returnPortalHeaderText: data.settings.returnPortalHeaderText,
      returnPortalMissingOrderHeaderText:
        data.settings.returnPortalMissingOrderHeaderText,
      support: data.settings.support,
      showCustomerWidgetInReturnApp:
        !!data.settings.customerWidget?.general
          ?.showCustomerWidgetInReturnApp && !!data.settings.support,
      returnPortalButtonText: data.settings.returnPortalButtonText,
      returnPortalGiftButtonText: data.settings.returnPortalGiftButtonText,
      deductLabelFromCredit: data.settings.deductLabelFromCredit,
      deductLabelFromRefund: data.settings.deductLabelFromRefund,
      hidePortalBranding: data.settings.hidePortalBranding,
      exchangeGroups: data.settings.exchangeGroups,
      extendedWarranties: data.settings.extendedWarranties,
      variantExchangeTitle: data.settings.variantExchangeTitle,
      exchangeGroupsExchangeTitle: data.settings.exchangeGroupsExchangeTitle,
      sameItemTitle: data.settings.sameItemTitle,
      newItemTitle: data.settings.newItemTitle,
      showLabelExpirationDate: data.settings.showLabelExpirationDate,
      labelExpiration: data.settings.labelExpiration,
      bundleRulesList: data.settings.bundleRulesList,
      instantRefundEnabled: data.settings.instantRefundEnabled,
      differentPricedVariantExchanges:
        data.settings.exchanges.differentPricedVariantExchanges,
      variantExchangeProductTags:
        data.settings.exchanges.variantExchangeProductTags,
      orderTracking: data.settings.orderTracking,
      returnTracking: data.settings.returnTracking,
      marketing: data.settings.marketing,
      resourceOverride: data.settings.resourceOverride,
      portalExcludedProductTags: data.settings.portalExcludedProductTags || [],
      pickupEnabled: data.settings.pickupEnabled,
      exchangeOptionText: data.theme.exchangeOptionText,
      storeCreditOptionText: data.theme.storeCreditOptionText,
      refundOptionText: data.theme.refundOptionText,
      enableNonShopifyClaims: !!data.settings.enableNonShopifyClaims,
      nonShopifyClaimsCollection: data.settings.nonShopifyClaimsCollection,
      nonShopifyClaimsDisableEditQuantity:
        data.settings.nonShopifyClaimsDisableEditQuantity,
      convertRecyclingProducts: data.settings.convertRecyclingProducts,
      recyclingProductId: data.settings.recyclingProductId,
      hideManualReviewStep: data.settings.hideManualReviewStep,
      hideFeeInCompensationMethodModal:
        data.settings.hideFeeInCompensationMethodModal,
      hideSummaryCard: data.settings.hideSummaryCard,
      bulkReturnsEnabled: data.settings?.bulkReturnsEnabled,
      loadAllAdvancedExchangeProducts:
        data.settings?.loadAllAdvancedExchangeProducts,
      hideGiftOption: data.settings.hideGiftOption,
      claimReviewHeader: data.theme.claimReviewHeader,
      claimShippingLineItemText: data.theme.claimShippingLineItemText,
      automation: data.automation,
      customerPortalVersion:
        data.settings.customerPortalVersion ?? CustomerPortalVersion.V3_5,
      bundles: data.settings.bundles,
      brandKit: data.settings.brandKit,
      packagePickupVariants: data.settings.packagePickupVariants,
      useShopifyStorefrontForCommentSold:
        data.settings?.commentsold?.useShopifyStorefront ?? false,
      dontChargeMerchantForDamagedOrWrongProductReturnLabels:
        data.settings.returns
          ?.dontChargeMerchantForDamagedOrWrongProductReturnLabels ?? false,
    };
    return { storefrontClient, settings };
  }, []);
  const settings = settingsLoad.value?.settings;
  const storefrontClient = settingsLoad.value?.storefrontClient;

  useStyleProperty(
    document.documentElement,
    "--redo-primary-color",
    settings?.theme.button_background_color ?? "inherit",
  );
  useStyleProperty(
    document.documentElement,
    "--redo-text-color",
    settings?.theme.button_text_color ?? "inherit",
  );
  useStyleProperty(
    document.documentElement,
    "--redo-primary-button-color",
    settings?.theme.button_background_color ?? "inherit",
  );
  useStyleProperty(
    document.documentElement,
    "--redo-accent-color",
    settings?.theme.accent_color ?? "inherit",
  );
  useStyleProperty(
    document.documentElement,
    "--redo-accent-color-background",
    settings?.theme.accent_background_color ?? "inherit",
  );
  useStyleProperty(
    document.documentElement,
    "--return-app-body-font-family",
    settings?.theme.body_font_family ?? "inherit",
  );
  useStyleProperty(
    document.documentElement,
    "--return-app-title-font-family",
    settings?.theme.title_font_family ?? "inherit",
  );
  if (!settings) {
    return (
      <Flex align="center" flex={1} justify="center">
        <LoadingRedoAnimation />
      </Flex>
    );
  }

  const onNewLogin = settings.returns?.newLogin || previewNewLogin;

  return (
    <>
      {createPortal(
        <style>
          {onNewLogin
            ? settings.theme.new_custom_css
            : settings.theme.custom_css}
        </style>,
        document.body,
      )}
      <SettingsContext.Provider value={settings}>
        <StorefrontClientContext.Provider value={storefrontClient ?? null}>
          {children}
        </StorefrontClientContext.Provider>
      </SettingsContext.Provider>
    </>
  );
});
