import { RouteObject } from "react-router-dom";
import { Redirect } from "../app/redirect";

/**
 * These routes are always unauthenticated.
 *
 * They are used to start the registration process for online and retail orders.
 * The retail route will start the registration process in an unauthenticated state
 * and allow the user to submit the registration without being logged in.
 *
 * The online route will redirect to the login page.
 */
export const registerRoutes: RouteObject[] = [
  {
    // Uncomment to enable these routes
    // element: <Outlet />,
    element: <Redirect preserveQuery to="/returns-portal" />,
    children: [
      {
        path: "retail",
        children: [
          {
            index: true,
            lazy: async () => {
              return { element: <div>Retail registration</div> };
            },
          },
          {
            path: ":id",
            lazy: async () => {
              return { element: <div>Retail registration confirmation</div> };
            },
          },
        ],
      },
    ],
  },
];
