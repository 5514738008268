import { Trackable, TrackableType } from "@redotech/redo-model/trackable";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import Printer from "@redotech/redo-web/arbiter-icon/printer.svg";
import QrCode from "@redotech/redo-web/arbiter-icon/qr-code-01.svg";
import { Flex } from "@redotech/redo-web/flex";
export function LabelButtons({
  trackable,
  carrier,
  commercialInvoice,
}: {
  trackable: Trackable & { type: TrackableType.RETURN };
  carrier: string | undefined;
  commercialInvoice: string | null | undefined;
}) {
  function openUrl(url: string) {
    window.open(url, "_blank");
  }

  return (
    <Flex flexDirection="row" gap="sm" grow={1} pt="xs">
      {!!trackable.postage_label && (
        <RedoButton
          hierarchy="secondary"
          IconLeading={Printer}
          onClick={() =>
            trackable.postage_label && openUrl(trackable.postage_label)
          }
          text="Print label"
        />
      )}
      {!!trackable.form_label && !trackable.pickup && carrier != "UPS" && (
        <RedoButton
          hierarchy="secondary"
          IconLeading={QrCode}
          onClick={() => trackable.form_label && openUrl(trackable.form_label)}
          text="QR code"
        />
      )}
      {!!commercialInvoice && (
        <RedoButton
          hierarchy="secondary"
          onClick={() => openUrl(commercialInvoice)}
          text="Print commercial invoice"
        />
      )}
    </Flex>
  );
}
