import { ReactNode, createContext, useState } from "react";

export type FlowStep =
  | typeof FlowStep.PRODUCT_SELECT
  | typeof FlowStep.ITEMS
  | typeof FlowStep.RETURN_METHOD
  | typeof FlowStep.REVIEW
  | typeof FlowStep.CONFIRMATION;

export namespace FlowStep {
  export const PRODUCT_SELECT = Symbol("product-select");
  export const ITEMS = Symbol("items");
  export const REVIEW = Symbol("review");
  export const CONFIRMATION = Symbol("confirmation");
  export const RETURN_METHOD = Symbol("returnMethod");
}

// If the user chooses "Returning a gift" we fill this data in to store their data.
const FlowStepContext = createContext({
  flowStep: FlowStep.ITEMS as FlowStep,
  setFlowStep: (flowStep: FlowStep) => {},
  submitting: false,
  setSubmitting: (submitting: boolean) => {},
});

const FlowStepContextProvider = ({ children }: { children: ReactNode }) => {
  const [flowStep, setFlowStep] = useState<FlowStep>(FlowStep.ITEMS);
  const [submitting, setSubmitting] = useState(false);

  return (
    <FlowStepContext.Provider
      value={{ flowStep, setFlowStep, submitting, setSubmitting }}
    >
      {children}
    </FlowStepContext.Provider>
  );
};

export { FlowStepContext, FlowStepContextProvider };
