import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { Flex } from "@redotech/redo-web/flex";
import { memo } from "react";

export const InStorePackingSlipButton = memo(function InStorePackingSlipButton({
  packingSlipLink,
}: {
  packingSlipLink: string | undefined;
}) {
  if (!packingSlipLink) {
    return null;
  }

  return (
    <Flex>
      <RedoButton
        hierarchy="secondary"
        onClick={() => window.open(packingSlipLink, "_blank")}
        text="Print return authorization"
      />
    </Flex>
  );
});
