import { ReturnConfirmation } from "@redotech/redo-customer-portal-app/portal-four/returns/confirmation";
import { RouteObject } from "react-router-dom";
import { Page } from "../order/page";
export const returnRoutes: RouteObject[] = [
  {
    path: ":returnId/completeExchange",
    lazy: async () => {
      const { CompleteExchange } = await import("./complete-exchange");
      return { Component: CompleteExchange };
    },
  },
  {
    path: ":returnId",
    lazy: async () => {
      return {
        element: (
          <Page showBackButton={false}>
            <ReturnConfirmation />
          </Page>
        ),
      };
    },
  },
];
