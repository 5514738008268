// extracted by mini-css-extract-plugin
export var bubble = "_redotech-redo-web-src-user-image-bubble";
export var container = "_redotech-redo-web-src-user-image-container";
export var contrastBorder = "_redotech-redo-web-src-user-image-contrast-border";
export var greyOut = "_redotech-redo-web-src-user-image-grey-out";
export var initialsWrapper = "_redotech-redo-web-src-user-image-initials-wrapper";
export var large = "_redotech-redo-web-src-user-image-large";
export var medium = "_redotech-redo-web-src-user-image-medium";
export var mediumSmall = "_redotech-redo-web-src-user-image-medium-small";
export var micro = "_redotech-redo-web-src-user-image-micro";
export var small = "_redotech-redo-web-src-user-image-small";
export var tiny = "_redotech-redo-web-src-user-image-tiny";
export var xTiny = "_redotech-redo-web-src-user-image-x-tiny";