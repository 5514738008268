import { Address } from "./team";

import type { InteractiveMapLocation } from "./draft-return/draft-return";

export type { InteractiveMapLocation };

export function parseAddressAsInteractiveMapLocation(
  address: Address,
): InteractiveMapLocation {
  return {
    name: address.name,
    address: {
      address1: address.street1,
      address2: address.street2,
      city: address.city,
      state: address.state,
      zip: address.zip,
      country: address.country,
    },
    geo:
      address.longitude !== undefined && address.latitude !== undefined
        ? { latitude: address.latitude, longitude: address.longitude }
        : undefined,
  };
}
