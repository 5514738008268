import { useLoad } from "@redotech/react-util/load";
import {
  ActivityStatus,
  Status,
} from "@redotech/redo-model/returns/temporal-status";
import { useContext, useState } from "react";
import { RpcClientContext } from "../../contexts/rpc";

interface UseCreationStatusResult {
  statuses: ActivityStatus[] | undefined;
  completelyFinished: boolean;
}
export const useCreationStatus = ({
  returnId,
}: {
  returnId: string;
}): UseCreationStatusResult => {
  const client = useContext(RpcClientContext);
  const [creationStatus, setCreationStatus] = useState<
    ActivityStatus[] | undefined
  >();

  const [completelyFinished, setCompletelyFinished] = useState(false);

  useLoad(
    async (signal) => {
      if (!client || !returnId) {
        return;
      }

      try {
        const iterator = client.getReturnCreationStatus({ returnId });

        for await (const { activityStatuses } of iterator) {
          setCreationStatus(activityStatuses);
          setCompletelyFinished(
            activityStatuses.every(
              (status) =>
                status.status === Status.COMPLETED ||
                status.status === Status.FAILED,
            ),
          );
        }
      } catch (e) {
        if (signal.aborted) {
          return;
        }
        throw e;
      }
    },
    [client, returnId],
  );

  return { statuses: creationStatus, completelyFinished };
};
