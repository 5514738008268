import { SettlementSchema } from "@redotech/redo-model/draft-return/draft-return";
import { ChannelZod } from "@redotech/redo-model/return";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const submitDraftReturn = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    settlement: SettlementSchema.nullish(),
    paymentToken: z.string().nullish(),
    channel: ChannelZod.optional(),
  }),
  output: z.object({ returnId: z.string() }),
};
