import { Currency } from "@redotech/money/currencies";
import { Subtotal } from "@redotech/redo-customer-portal-shared/types";
import { CURRENCY_FORMAT } from "@redotech/redo-customer-portal-shared/util";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import { IndentedTotals } from "./review/indented-totals";
import * as summaryCss from "./summary-card.module.css";

export const SummaryCard = memo(function SummaryCard({
  labelDeducted,
  shippingFee,
  totalShippingFee,
  pickupFee,
  newOrderValue,
  currency,
  totalPaymentOwed,
  totalRefund,
  totalStoreCredit,
  feesHeader,
  feesOrderHeader,
  adjustmentValue,
  adjustmentValueString,
  returnValue,
  newOrderTaxes,
  newVariantExchangeItemSubtotals,
  newOrderItemsSubtotals,
  productSubtotals,
  taxSubtotals,
  submitted,
}: {
  feesHeader: string;
  feesOrderHeader: string;
  labelDeducted: boolean;
  shippingFee: number;
  totalShippingFee: number;
  pickupFee?: number;
  newOrderValue: number;
  currency: Currency;
  totalPaymentOwed: number;
  totalRefund: number;
  totalStoreCredit: number;
  adjustmentValue?: number;
  adjustmentValueString?: string;
  returnValue: number;
  newOrderTaxes: Subtotal[];
  newVariantExchangeItemSubtotals: Subtotal[];
  newOrderItemsSubtotals: Subtotal[];
  productSubtotals: Subtotal[];
  taxSubtotals: Subtotal[];
  submitted: boolean;
}) {
  const currencyFormatter = CURRENCY_FORMAT(currency);

  let owedMinusShipping = totalPaymentOwed;
  if (totalShippingFee) {
    owedMinusShipping = totalPaymentOwed - totalShippingFee;
  }

  const isUpsell = owedMinusShipping > 0;
  const sign = isUpsell ? -1 : 1;

  return (
    <Flex flexDirection="column" gap="xl">
      <Text fontSize="lg" fontWeight="semibold">
        Summary
      </Text>
      {newOrderValue > 0 && isUpsell && (
        <IndentedTotals
          currencyFormatter={currencyFormatter}
          subtotals={[
            ...newOrderItemsSubtotals,
            ...newVariantExchangeItemSubtotals,
            ...newOrderTaxes,
          ]}
          title={feesOrderHeader}
          total={newOrderValue}
        />
      )}
      <IndentedTotals
        currencyFormatter={currencyFormatter}
        subtotals={[...productSubtotals, ...taxSubtotals]}
        title={feesHeader}
        total={returnValue * sign}
      />
      {adjustmentValue && adjustmentValueString && adjustmentValue !== 0 ? (
        <Flex dir="row" justify="space-between">
          <Text fontSize="sm">{adjustmentValueString}</Text>
          <Text fontSize="sm" textColor="secondary">
            {currencyFormatter.format(adjustmentValue * sign)}
          </Text>
        </Flex>
      ) : null}
      {newOrderValue > 0 && !isUpsell && (
        <IndentedTotals
          currencyFormatter={currencyFormatter}
          subtotals={[
            ...newOrderItemsSubtotals,
            ...newVariantExchangeItemSubtotals,
            ...newOrderTaxes,
          ]}
          title={feesOrderHeader}
          total={newOrderValue * -1}
        />
      )}
      {labelDeducted && (
        <ShippingFees
          currency={currency}
          negative
          pickupFee={pickupFee}
          shippingFee={shippingFee}
        />
      )}
      <Divider />
      {owedMinusShipping > 0 && (
        <>
          <Flex dir="row" justify="space-between">
            <Text fontSize="sm">Due later</Text>
            <Text fontSize="sm" fontWeight="semibold" textColor="primary">
              {currencyFormatter.format(owedMinusShipping)}
            </Text>
          </Flex>
          <Text fontSize="sm" textColor="primary">
            {`You will be sent an invoice for the remaining `}
            <strong>{currencyFormatter.format(owedMinusShipping)}</strong>
            {` balance for your new items once your return has been handled`}
          </Text>
        </>
      )}
      {totalStoreCredit > 0 && (
        <Flex dir="row" justify="space-between">
          <Text fontSize="sm" textColor="secondary">
            Store credit
          </Text>
          <Text fontSize="sm" fontWeight="semibold" textColor="primary">
            {currencyFormatter.format(totalStoreCredit)}
          </Text>
        </Flex>
      )}
      {totalRefund > 0 && (
        <Flex dir="row" justify="space-between">
          <Text fontSize="sm" textColor="secondary">
            Refund owed
          </Text>
          <Text fontSize="sm" fontWeight="semibold" textColor="primary">
            {currencyFormatter.format(totalRefund)}
          </Text>
        </Flex>
      )}

      {totalShippingFee > 0 && !labelDeducted && (
        <>
          <Divider classes={summaryCss.fullDivider} />
          <Text fontSize="sm" fontWeight="semibold">
            Shipping
          </Text>
          <ShippingFees
            currency={currency}
            pickupFee={pickupFee}
            shippingFee={shippingFee}
          />
          <Divider />

          <Flex dir="row" justify="space-between">
            <Text fontSize="sm">{submitted ? `Paid` : `Due now`}</Text>
            <Text fontSize="sm" fontWeight="semibold" textColor="primary">
              {currencyFormatter.format(totalShippingFee)}
            </Text>
          </Flex>
        </>
      )}
    </Flex>
  );
});

export const ShippingFees = memo(function ShippingFees({
  shippingFee,
  pickupFee,
  currency,
  negative,
}: {
  shippingFee: number;
  currency: Currency;
  pickupFee?: number;
  negative?: boolean;
}) {
  const currencyFormatter = CURRENCY_FORMAT(currency);
  return (
    <>
      <Flex dir="row" justify="space-between">
        <Text fontSize="sm" textColor="secondary">
          Return shipping
        </Text>
        <Text fontSize="sm" textColor="secondary">
          {`${negative ? "-" : ""}${currencyFormatter.format(shippingFee)}`}
        </Text>
      </Flex>
      {pickupFee && (
        <Flex dir="row" justify="space-between">
          <Text fontSize="sm" textColor="secondary">
            Package pickup
          </Text>
          <Text fontSize="sm" textColor="secondary">
            {`${negative ? "-" : ""}${currencyFormatter.format(pickupFee)}`}
          </Text>
        </Flex>
      )}
    </>
  );
});
