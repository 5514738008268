import { useRequiredContext } from "@redotech/react-util/context";
import { LoadState, useLoad } from "@redotech/react-util/load";
import { InteractiveMapLocation } from "@redotech/redo-model/draft-return/draft-return";
import { parseAddressAsInteractiveMapLocation } from "@redotech/redo-model/interactive-map-location";
import { RpcClientContext } from "../../contexts/rpc";
import { ReturnAppSettings } from "../../settings";

export type InStoreLocationsResponse = {
  locationsLoad: LoadState<InteractiveMapLocation[]>;
};

export const useInStoreLocations = (
  draftReturnId: string | undefined,
  returnId: string | undefined,
  settings: ReturnAppSettings | undefined,
) => {
  const client = useRequiredContext(RpcClientContext);
  const locationsLoad: LoadState<InteractiveMapLocation[]> =
    useLoad(async () => {
      if (!draftReturnId && !returnId) {
        return [];
      }
      const { locations } = await client.getLocations({
        draftReturnId,
        returnId,
      });
      return [
        ...locations,
        ...(settings?.customInStoreAddresses?.map((address) =>
          parseAddressAsInteractiveMapLocation(address),
        ) ?? []),
      ];
    }, [draftReturnId, returnId]);

  return { locationsLoad };
};
