// extracted by mini-css-extract-plugin
export var addButton = "_redotech-redo-web-src-tags-tag-input-add-button";
export var border = "_redotech-redo-web-src-tags-tag-input-border";
export var check = "_redotech-redo-web-src-tags-tag-input-check";
export var danger = "_redotech-redo-web-src-tags-tag-input-danger";
export var dropdownContainer = "_redotech-redo-web-src-tags-tag-input-dropdown-container";
export var editModalBody = "_redotech-redo-web-src-tags-tag-input-edit-modal-body";
export var footerContainer = "_redotech-redo-web-src-tags-tag-input-footer-container";
export var fullWidth = "_redotech-redo-web-src-tags-tag-input-full-width";
export var hide = "_redotech-redo-web-src-tags-tag-input-hide";
export var icon = "_redotech-redo-web-src-tags-tag-input-icon";
export var input = "_redotech-redo-web-src-tags-tag-input-input";
export var inputFlex = "_redotech-redo-web-src-tags-tag-input-input-flex";
export var inputWrapper = "_redotech-redo-web-src-tags-tag-input-input-wrapper";
export var manualFocus = "_redotech-redo-web-src-tags-tag-input-manual-focus";
export var optionButton = "_redotech-redo-web-src-tags-tag-input-option-button";
export var pillWrapper = "_redotech-redo-web-src-tags-tag-input-pill-wrapper";
export var pointer = "_redotech-redo-web-src-tags-tag-input-pointer";
export var showOverflow = "_redotech-redo-web-src-tags-tag-input-show-overflow";
export var tagsFlex = "_redotech-redo-web-src-tags-tag-input-tags-flex";
export var xButton = "_redotech-redo-web-src-tags-tag-input-x-button";