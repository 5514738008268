import { z } from "zod";
import { zExt } from "./common/zod-util";
import { ReturnProductSchema } from "./draft-return/draft-return-items";
import { ExtendedWarrantySchema } from "./extended-warranty";

export const WarrantyRegistrationSchema = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  email: z.string(),
  name: z.string(),
  product: ReturnProductSchema,
  responses: z.array(
    z.object({ type: z.string(), value: z.any(), message: z.string() }),
  ),
  customer: zExt.objectId(),
  returnId: zExt.objectId().nullish(),
  extendedWarranty: ExtendedWarrantySchema.nullish(),
  optInMarketing: z.boolean().nullish(),
  discountCode: z.string().nullish(),
  // PaymentIntent is excluded since it contains sensitive data
});

export type WarrantyRegistration = z.infer<typeof WarrantyRegistrationSchema>;
