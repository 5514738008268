import { Payer } from "@redotech/redo-model/return-flow";

import { ShipmentMethodName } from "@redotech/redo-model/draft-return/draft-return";

import { CustomerPortalRpcClient } from "@redotech/customer-portal-sdk/rpc/client";
import { DraftReturn } from "@redotech/redo-model/draft-return/draft-return";
import {
  getCompensationMethod,
  hasReturnItemsThatRequireShipping,
} from "@redotech/redo-model/draft-return/util";
import { ReturnTypeEnum } from "@redotech/redo-model/return";
import { Big } from "big.js";
import { ReturnAppSettings } from "../../settings";
import { ShipmentMethodOption } from "../../shipment-method/util";

export const getShippingMethodOptions = async (
  draftReturn: DraftReturn,
  client: CustomerPortalRpcClient,
  settings: ReturnAppSettings | undefined,
) => {
  // If no shipping is required, then skip all shipping methods
  if (!hasReturnItemsThatRequireShipping(draftReturn)) {
    console.error("shipmentMethodOptions: No shipping required");
    return [];
  }

  console.log("shipmentMethodOptions: Getting shipping estimate");
  const shipments = await client.getShippingEstimate({
    draftReturnId: draftReturn._id,
  });

  console.log("shipmentMethodOptions: shipments", ...shipments);

  const shippingMethodOptions: ShipmentMethodOption[] = [];

  const totalShipmentFee = shipments.reduce((acc, shipment) => {
    return acc.add(shipment.fee);
  }, new Big(0));

  const labelPayer = getCompensationMethod(draftReturn)?.payer;

  //Happy returns
  // if (
  //   shipments.length > 0 &&
  //   shipments.every((shipment) => !!shipment.happyReturnsDetails)
  // ) {
  //   const totalHappyReturnsFee = shipments.reduce((acc, shipment) => {
  //     return acc.add(shipment.happyReturnsDetails!.price);
  //   }, new Big(0));

  //   shippingMethodOptions.push({
  //     type: ShipmentMethodName.HAPPY_RETURNS,
  //     nonPickupFee:
  //       labelPayer === Payer.CUSTOMER ? totalHappyReturnsFee : new Big(0),
  //     happyReturnsDetails: {
  //       ...shipments[0]!.happyReturnsDetails!,
  //     },
  //   });

  //   shippingMethodOptions.push({
  //     type: ShipmentMethodName.SELF_SHIP,
  //     nonPickupFee:
  //       labelPayer === Payer.CUSTOMER ? totalShipmentFee : new Big(0),
  //   });
  //   return shippingMethodOptions;
  // }

  const pickupFee =
    shipments?.length === 1 && shipments?.[0]?.pickupFee
      ? shipments[0].pickupFee
      : undefined;

  // If pickup fee, is not pickup ineligibility, and not warranty
  if (pickupFee) {
    shippingMethodOptions.push({
      type: ShipmentMethodName.PICKUP,
      pickupFee: new Big(pickupFee),
      nonPickupFee:
        labelPayer === Payer.CUSTOMER ? totalShipmentFee : new Big(0),
    });
  }

  // Check if return in store is available
  if (
    settings?.returnInStoreEnabled &&
    draftReturn.returnType !== ReturnTypeEnum.WARRANTY
  ) {
    shippingMethodOptions.push({ type: ShipmentMethodName.IN_STORE });
  }

  shippingMethodOptions.push({
    type: ShipmentMethodName.SELF_SHIP,
    nonPickupFee: labelPayer === Payer.CUSTOMER ? totalShipmentFee : new Big(0),
  });

  // Check if settlement is available
  // if (
  //   settings?.returns?.settlementEnabled &&
  //   settings?.returns?.settlementRefund &&
  //   settings?.returns?.settlementRefund > 0
  // ) {
  //   const orderValue = getCompensationMethod(
  //     returnContext.draftReturn,
  //   )?.totalReturnableValue;
  //   if (orderValue?.gt(0)) {
  //     shippingMethodOptions.push({ type: ShipmentMethodName.SETTLEMENT, orderValue });
  //   }
  // }
  return shippingMethodOptions;
};
