import { RouteObject } from "react-router-dom";

export const warrantiesRoutes: RouteObject[] = [
  {
    lazy: async () => {
      const { Page } = await import("../login/page");

      return { element: <Page /> };
    },
    children: [
      {
        index: true,
        path: "",
        lazy: async () => {
          const { Page } = await import("../order/page");
          const { ThirdPartyWarranty } = await import(
            "../order/third-party-warranty"
          );
          return {
            element: (
              <Page alwaysShowNavbar>
                <ThirdPartyWarranty />
              </Page>
            ),
          };
        },
      },
      {
        path: "register",
        lazy: async () => {
          const { RegisterWarranty } = await import("./register-warranty");
          const { Page } = await import("../order/page");
          return {
            element: (
              <Page alwaysShowNavbar>
                <RegisterWarranty />
              </Page>
            ),
          };
        },
      },
      // TODO: Combine these routes
      {
        path: ":id",
        lazy: async () => {
          const { ReturnStatus } = await import("../order/return-status");
          const { Page } = await import("../order/page");
          return {
            element: (
              <Page>
                <ReturnStatus />
              </Page>
            ),
          };
        },
      },
      {
        path: ":warrantyId/warranty",
        lazy: async () => {
          const { ReturnPortal } = await import("../order/return-portal");
          const { Page } = await import("../order/page");
          return {
            element: (
              <Page>
                <ReturnPortal />
              </Page>
            ),
          };
        },
      },
      {
        path: ":warrantyId/details",
        lazy: async () => {
          const { RegisteredWarrantyDetails } = await import(
            "./registered-warranty-details"
          );
          const { Page } = await import("../order/page");
          return {
            element: (
              <Page showBackButton={false}>
                <RegisteredWarrantyDetails />
              </Page>
            ),
          };
        },
      },
    ],
  },
];
