import { DraftReturn } from "@redotech/redo-model/draft-return/draft-return";
import { createContext, ReactNode, useState } from "react";

export const ReturnContext = createContext<{
  draftReturn: DraftReturn | undefined;
  setDraftReturn: (draftReturn: DraftReturn | undefined) => void;
}>({ draftReturn: undefined, setDraftReturn: () => {} });

export const ReturnProvider = ({ children }: { children: ReactNode }) => {
  const [draftReturn, setDraftReturn] = useState<DraftReturn | undefined>(
    undefined,
  );
  return (
    <ReturnContext.Provider value={{ draftReturn, setDraftReturn }}>
      {children}
    </ReturnContext.Provider>
  );
};
