import { ReactNode, createContext, useState } from "react";

const ShippingContext = createContext({
  // @ts-expect-error: strict-null-checks
  newOrderShippingName: undefined as string,
  setNewOrderShippingName: (newOrderShippingName: string) => {},
  greenReturnOverride: undefined as boolean | undefined,
  setGreenReturnOverride: (greenReturnOverride: boolean | undefined) => {},
});

const ShippingContextProvider = ({ children }: { children: ReactNode }) => {
  const [newOrderShippingName, setNewOrderShippingName] =
    // @ts-expect-error: strict-null-checks
    useState<string>(undefined);
  const [greenReturnOverride, setGreenReturnOverride] = useState<
    boolean | undefined
  >(undefined);

  return (
    <ShippingContext.Provider
      value={{
        newOrderShippingName,
        setNewOrderShippingName,
        greenReturnOverride,
        setGreenReturnOverride,
      }}
    >
      {children}
    </ShippingContext.Provider>
  );
};

export {
  ShippingContext as ShippingContext,
  ShippingContextProvider as ShippingContextProvider,
};
